import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import React, { useCallback, useRef } from 'react';
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Check, CheckCircle, Edit, Error, Extension, Navigation } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import { formatEuropeanDate, formatEuropeanDateTime } from '../../../../helpers/date-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { buildCellStringArray } from '../../../common/AccordionForStringArray';
import { mapToiletTypeKey } from '../../../tables/Base';
import { toiletTypePredicate } from '../../../tables/components/Cells';
import { buildCellObject } from '../serviceWorker/UserServiceWorkerManagementPageDataTable';
import { ReactComponent as LicenseSvg } from '../../../../assets/svgs/license_grey.svg'
import { primaryColor } from '../../../../App';
import { saveExcelFile } from '../../../../helpers/tables/common';
import { ReactComponent as MotionSensorSvg } from '../../../../assets/svgs/motion-sensor.svg';
import { buildCellAssignedColumn } from '../../../tables/GroupSelectionTable';
import { useTranslation } from 'react-i18next';

const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
        saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Checkpoint');
    });
};


export const BatteryDisplaySelectionTable = ({ groups, selectedGroups, onSelectionChange,
    selectedObjectId, selectedServiceWorkerId, objects, userServiceWorkers,
    onEdit, onExtendLicense, onNavigate, extendBssdSensorLicense }
    : {
        groups: GroupLicenseTable[], selectedGroups: GroupLicenseTable[], onSelectionChange: any,
        selectedObjectId?: string | undefined, selectedServiceWorkerId?: string | undefined, objects?: ObjectUnit[] | undefined, userServiceWorkers?: UserServiceWorker[] | undefined,
        onEdit?: any, onExtendLicense?: any, onNavigate?: any, extendBssdSensorLicense?: any
    }) => {

    const exporterRef: any = useRef(null);
    const {t} = useTranslation();
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    const [pageSize, setPageSize] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(0);


    const Cell = (props: any) => {
        const { column, row }: { column: any, row: GroupLicenseTable } = props;
        if (column.name === GroupLicenseTable.Columns.sensorLicenseValidUntil && row.sensorLicenses) {
            return buildCellStringArray(props, row.sensorLicenses.map(x => formatEuropeanDate(x.sensorLicenseValidUntilDate)), `Enthält ${row.sensorLicenses.length} Sensor Lizenzen`, null,
                row.sensorLicenses.map(x => <MotionSensorSvg fill={x.isSensorLicenseExpired ? "#eb4034" : "#2ee656"} />));
        }
        if (column.name === GroupLicenseTable.Columns.deviceSensorId && row.sensors) {
            return buildCellStringArray(props, row.sensors.map(x => x.id), `Enthält ${row.sensors.length} Sensoren`, null,
                row.sensors.map(x => <MotionSensorSvg />));
        }
        if (column.name == GroupLicenseTable.Columns.currentAssignedObject) {
            return buildCellObject(objects?.find(x => x.id == row.objectUnitId), props);
        }
        if (column.name == GroupLicenseTable.Columns.assignedObject) {
            return buildCellAssignedColumn(row.objectUnitId == selectedObjectId, props);
        }
        if (column.name == GroupLicenseTable.Columns.assignedServiceWorker) {
            return buildCellAssignedColumn(row.userServiceWorkerIds.includes(selectedServiceWorkerId || ""), props);
        }
        return <Table.Cell {...props} />;
    };

    let columns = [
        {
            name: GroupLicenseTable.Columns.notificationName,
            title: 'Einheit Name',
            getCellValue: (row: GroupLicenseTable) => row.notificationName,
        },
        {
            name: GroupLicenseTable.Columns.objectName,
            title: 'Objekt Name',
            getCellValue: (row: GroupLicenseTable) => row.objectName,
        },
        {
            name: GroupLicenseTable.Columns.levelName,
            title: 'Ebene',
            getCellValue: (row: GroupLicenseTable) => row.levelName,
        },
        {
            name: GroupLicenseTable.Columns.roomName,
            title: 'Kunde',
            getCellValue: (row: GroupLicenseTable) => row.roomName,
        },
        {
            name: GroupLicenseTable.Columns.toiletType,
            title: 'Raumart',
            getCellValue: (row: GroupLicenseTable) => mapToiletTypeKey(row.toiletType),
        },
        {
            name: GroupLicenseTable.Columns.userPropertyName,
            title: t("myUnitsSettings.featureName"),
            getCellValue: (row: GroupLicenseTable) => row.userPropertyName,
        },
        {
            name: GroupLicenseTable.Columns.sensorLicenseValidUntil,
            title: t("myUnitsSettings.sensorLicensesValidUntil"),
            getCellValue: (row: GroupLicenseTable) => row.sensorLicenses ? row.sensorLicenses.map(x => formatEuropeanDate(x.sensorLicenseValidUntilDate)).join(',') : []
        },
        {
            name: GroupLicenseTable.Columns.deviceSensorId,
            title: 'Sensor Id',
            getCellValue: (row: GroupLicenseTable) => row?.sensors?.map(x=>x.id).join(","),
        },
        {
            name: GroupLicenseTable.Columns.groupCreatedDate,
            title: t("myUnitsSettings.unitActivatedOn"),
            getCellValue: (row: GroupLicenseTable) => formatEuropeanDateTime(new Date(row.groupCreatedDate))
        }
    ];

    if (!!onEdit) {
        const item = {
            name: GroupLicenseTable.Columns.actions,
            title: 'Bearbeiten',
            getCellValue: (row: GroupLicenseTable) => {
                return <>
                    {onEdit &&
                        <Tooltip title={t("myUnitsSettings.edit")}>
                            <IconButton
                                color="primary"
                                onClick={async () => {
                                    await onEdit(row);
                                }}
                                size="large">
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    }
                    {onNavigate &&
                        <Tooltip title="Battery Smart Service Display verwalten">
                            <IconButton
                                color="primary"
                                onClick={async () => {
                                    await onNavigate(row);
                                }}
                                size="large">
                                <Navigation />
                            </IconButton>
                        </Tooltip>
                    }
                </>;
            }
        } as any;
        columns = [item, ...columns];
    }
    if (!!onEdit) {
        const item2 = {
            name: GroupLicenseTable.Columns.actions2,
            title: 'Lizenz Verwalten',
            getCellValue: (row: GroupLicenseTable) => {
                return <>
                    {onExtendLicense &&
                        <Tooltip title="Lizenz erweitern">
                            <IconButton
                                color="primary"
                                onClick={async () => {
                                    await onExtendLicense(row);
                                }}
                                size="large">
                                <LicenseSvg fill={primaryColor} />
                            </IconButton>
                        </Tooltip>
                    }
                    {extendBssdSensorLicense &&
                        <Tooltip title="Sensor Lizenz zuweisen">
                            <IconButton
                                color="primary"
                                onClick={async () => {
                                    extendBssdSensorLicense(row);
                                }}
                                size="large">
                                <MotionSensorSvg />
                            </IconButton>
                        </Tooltip>
                    }
                </>;
            }
        } as any;
        columns = [item2, ...columns];
    }

    let columnWidth = Object.keys(GroupLicenseTable.Columns).map(el => {
        if (el == GroupLicenseTable.Columns.actions) {
            return { columnName: el, width: 220 }
        }
        if (el == GroupLicenseTable.Columns.sensorLicenseValidUntil) {
            return { columnName: el, width: 300 }
        }
        return { columnName: el, width: 170 }
    });

    if (selectedObjectId) {
        const objectAssignedColumn = {
            name: GroupLicenseTable.Columns.assignedObject,
            title: 'Zugewiesen',
            getCellValue: (row: GroupLicenseTable) => row.objectUnitId == selectedObjectId ? "Ja" : "Nein"
        }
        const currentObjectAssignedColumn = {
            name: GroupLicenseTable.Columns.currentAssignedObject,
            title: 'Aktuelles Objekt zugewiesen',
            getCellValue: (row: GroupLicenseTable) => {
                let object = objects?.find(x => x.id == row.objectUnitId);
                return (
                    !object ? "Keines Zugewiesen" : `${object?.name} ${object?.customerName} ${object?.addressLine} ${object?.city}`
                );
            }
        }
        columns = [objectAssignedColumn, currentObjectAssignedColumn, ...columns];

    }

    if (selectedServiceWorkerId) {
        const serviceWorkerAssignedColumn = {
            name: GroupLicenseTable.Columns.assignedServiceWorker,
            title: 'Zugewiesen',
            getCellValue: (row: GroupLicenseTable) => row.userServiceWorkerIds.includes(selectedServiceWorkerId) ? "Ja" : "Nein"
        }
        const currentServiceWorkersAssignedColumn = {
            name: GroupLicenseTable.Columns.currentAssignedServiceWorkers,
            title: 'Aktuelle Servicekräfte zugewiesen',
            getCellValue: (row: GroupLicenseTable) => {
                let userServiceWorkersForRow = userServiceWorkers?.filter(x => row.userServiceWorkerIds.includes(x.id));
                return (
                    !userServiceWorkersForRow ? "Keine Zugewiesen" : `${userServiceWorkersForRow.map(x => `${x.name} ${x.phoneNumber}`).join(',')}`
                );
            }
        }
        columns = [serviceWorkerAssignedColumn, currentServiceWorkersAssignedColumn, ...columns];
    }

    return (
        <>
            <Grid
                getRowId={(row) => row.id}
                rows={groups}
                columns={columns}
            >
                <FilteringState />
                <IntegratedFiltering columnExtensions={[
                    { columnName: GroupLicenseTable.Columns.toiletType, predicate: toiletTypePredicate },
                ]} />

                <SortingState
                    defaultSorting={[{ columnName: GroupLicenseTable.Columns.notificationName, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState
                    selection={selectedGroups.map(x => x.id)}
                    onSelectionChange={onSelectionChange}
                />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={columnWidth} />
                <TableColumnVisibility />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <TableHeaderRow />
                <TableFilterRow />
                <PagingPanel
                    pageSizes={[5, 10, 15, 0]}
                />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={groups}
                columns={columns}
                onSave={onSave}
            />
        </>
    );
};