import React from 'react';
import {
    Alert,
    AlertTitle,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Add, AddToHomeScreen, Mail, Save, SupervisedUserCircle, SupportAgent } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@mui/material';
import { UserProperty } from '../../../../models/UserProperty';
import { StringHelper } from '../../../../helpers/string-helper';
import { usePortalAccountStyles } from '../../../../styles/usePortalAccountStyles';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { ServiceWorkerType, UserServiceWorker } from '../../../../models/UserServiceWorker';
import { api } from '../../../../api';
import { UserType } from '../../../../models/UserType';
import { getUserIdFromStorage } from '../../../../helpers/common';
import PhoneInput from 'react-phone-number-input'
import { PhoneInputCommon } from '../../../common/PhoneInputTextFieldTelegramAdmin';
import { useTranslation } from 'react-i18next';

export const UserServiceWorkerManagementPageEditingDialog = ({ visible, setVisible, object, onSave, objects, onInviteUser = null }
    : { visible: boolean, setVisible: any, object?: UserServiceWorker, objects: UserServiceWorker[], onSave: any, onInviteUser?: any }) => {

    const [currentServiceWorker, setCurrentServiceWorker] = React.useState<UserServiceWorker>(UserServiceWorker.CreateUserServiceWorker());

    const [loading, setLoading] = React.useState(false);
    const [nameError, setNameError] = React.useState('');
    const { t } = useTranslation();

    const onClose = () => {
        setCurrentServiceWorker(UserServiceWorker.CreateUserServiceWorker());
        setVisible(false);
    }
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {

        (async () => {
            if (object) {
                setCurrentServiceWorker(object);
            } else {
                setCurrentServiceWorker(UserServiceWorker.CreateUserServiceWorker());
            }

            resetErrorText();

        })();

    }, [visible, object])


    const isValid = () => {
        let valid = true;

        if (StringHelper.IsNullOrWhiteSpace(currentServiceWorker.name)) {
            setNameError("Name ist erforderlich");
            enqueueSnackbar("Name pflicht!", { variant: 'error' });
            valid = false;
        }


        return valid;
    }

    const resetErrorText = () => {
        setNameError('');
    }


    const getInviteButtonLabel = (type: ServiceWorkerType) => {
        switch (type) {
            case ServiceWorkerType.LoginPhoneNumber:
                return "SMS App Einladung mit Code senden";
            case ServiceWorkerType.IncidentMessenger:
            case ServiceWorkerType.LoginUsername:
            case ServiceWorkerType.ObjectLeader:
            case ServiceWorkerType.ServiceWorkerPlus:
            case ServiceWorkerType.SuperServiceWorkerPlus:
                return "Login Code generieren";
        }
        return "";
    }

    const isValidForSave = () => {
        switch (currentServiceWorker.type) {
            case ServiceWorkerType.LoginPhoneNumber:
                return !StringHelper.IsNullOrWhiteSpace(currentServiceWorker.name) && !StringHelper.IsNullOrWhiteSpace(currentServiceWorker.phoneNumber);
            case ServiceWorkerType.LoginUsername:
            case ServiceWorkerType.IncidentMessenger:
                return !StringHelper.IsNullOrWhiteSpace(currentServiceWorker.name) && !StringHelper.IsNullOrWhiteSpace(currentServiceWorker.loginUsername);
            case ServiceWorkerType.ObjectLeader:
            case ServiceWorkerType.ServiceWorkerPlus:
            case ServiceWorkerType.SuperServiceWorkerPlus:
                return !StringHelper.IsNullOrWhiteSpace(currentServiceWorker.name) && !StringHelper.IsNullOrWhiteSpace(currentServiceWorker.email);
        }

    }

    return (
        <Dialog open={visible} onClose={onClose} fullWidth maxWidth="xl">
            <DefaultDialogTitle
                title="Servicekraft bearbeiten"
                handleClose={onClose}
            />

            <DialogContent>
                <Grid style={{ marginTop: 10 }} container xs={12} direction="row" spacing={3}>
                    <Grid item>
                        <Typography component="h4" variant="h4">Details</Typography>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="Name"
                            variant="filled"
                            value={currentServiceWorker.name}
                            onChange={(event: any) => { setCurrentServiceWorker({ ...currentServiceWorker, name: event.target.value }) }}
                        />
                    </Grid>
                    {currentServiceWorker.type == ServiceWorkerType.LoginPhoneNumber &&
                        <Grid item xs={12} lg={12}>
                            <PhoneInput
                                placeholder={t("myUnitsSettings.phoneNumber")}
                                value={currentServiceWorker.phoneNumber || "+"}
                                onChange={(event: any) => { setCurrentServiceWorker({ ...currentServiceWorker, phoneNumber: event }) }}
                                inputComponent={PhoneInputCommon}
                            />
                        </Grid>
                    }
                    {(currentServiceWorker.type == ServiceWorkerType.LoginUsername || currentServiceWorker.type == ServiceWorkerType.IncidentMessenger) &&
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label={t("myObjectSettings.loginUsername")}
                                variant="filled"
                                value={currentServiceWorker.loginUsername}
                                onChange={(event: any) => { setCurrentServiceWorker({ ...currentServiceWorker, loginUsername: event.target.value }) }}
                            />
                        </Grid>
                    }
                    {UserServiceWorker.isLeader(currentServiceWorker.type) &&
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label={t("myObjectSettings.loginEmail")}
                                variant="filled"
                                value={currentServiceWorker.email}
                                onChange={(event: any) => { setCurrentServiceWorker({ ...currentServiceWorker, email: event.target.value }) }}
                            />
                        </Grid>
                    }

                    <Grid item xs={12} lg={4}>
                        <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="RFID Chip Code"
                            variant="filled"
                            value={currentServiceWorker.nfcCode}
                            onChange={(event: any) => { setCurrentServiceWorker({ ...currentServiceWorker, nfcCode: event.target.value }) }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="Taqt NFC Code"
                            variant="filled"
                            value={currentServiceWorker.taqtNfcCode}
                            onChange={(event: any) => { setCurrentServiceWorker({ ...currentServiceWorker, taqtNfcCode: event.target.value }) }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            fullWidth
                            required
                            variant="outlined"
                            label="Max Loginzeit im Objekt"
                            type="number"
                            disabled={loading}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Minuten</InputAdornment>
                            }}
                            value={currentServiceWorker.autoLogoutObjectMinutes}
                            onChange={(event: any) => {
                                setCurrentServiceWorker({ ...currentServiceWorker, autoLogoutObjectMinutes: event.target.value })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={currentServiceWorker.isEnabled}
                                    onChange={(event: any) => { setCurrentServiceWorker({ ...currentServiceWorker, isEnabled: event.target.checked }) }}
                                    name="isEnabled"
                                    color="primary"
                                />
                            }
                            label="Servicekraft Aktiviert"
                        />
                    </Grid>
                    {UserServiceWorker.isLeader(currentServiceWorker.type) &&
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentServiceWorker.isQmNotificationEnabled}
                                        onChange={(event: any) => { setCurrentServiceWorker({ ...currentServiceWorker, isQmNotificationEnabled: event.target.checked }) }}
                                        name="isQmNotificationEnabled"
                                        color="primary"
                                    />
                                }
                                label="Objektleiter Benachrichtigung QM-Planungsauslösung aktiviert"
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <FormLabel id="demo-radio-buttons-group-label">t("myObjectSettings.serviceStaffType")</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="type"
                            value={currentServiceWorker.type}
                            onChange={(event: any) => { setCurrentServiceWorker({ ...currentServiceWorker, type: parseInt(event.target.value) }) }}
                        >
                            <Grid container direction={"row"}>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        value={ServiceWorkerType.LoginPhoneNumber}
                                        control={<Radio />}
                                        label={UserServiceWorker.GetLabel(ServiceWorkerType.LoginPhoneNumber)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Alert severity="info">
                                        Leistungsbestätigungen
                                    </Alert>
                                </Grid>
                            </Grid>
                            <Grid container direction={"row"}>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        value={ServiceWorkerType.LoginUsername}
                                        control={<Radio />}
                                        label={UserServiceWorker.GetLabel(ServiceWorkerType.LoginUsername)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Alert severity="info">
                                        Leistungsbestätigungen
                                    </Alert>
                                </Grid>
                            </Grid>
                            <Grid container direction={"row"}>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        value={ServiceWorkerType.IncidentMessenger}
                                        control={<Radio />}
                                        label={UserServiceWorker.GetLabel(ServiceWorkerType.IncidentMessenger)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Alert severity="info">
                                        Vorfallmeldungen
                                    </Alert>
                                </Grid>
                            </Grid>

                            <Grid container direction={"row"}>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        value={ServiceWorkerType.ObjectLeader}
                                        control={<Radio />}
                                        label={UserServiceWorker.GetLabel(ServiceWorkerType.ObjectLeader)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Alert severity="info">
                                        QM Management, Materialwirtschaft
                                    </Alert>
                                </Grid>
                            </Grid>

                            <Grid container direction={"row"}>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        value={ServiceWorkerType.ServiceWorkerPlus}
                                        control={<Radio />}
                                        label={UserServiceWorker.GetLabel(ServiceWorkerType.ServiceWorkerPlus)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Alert severity="info">
                                        Leistungsbestätigungen, QM Management, Materialwirtschaft
                                    </Alert>
                                </Grid>
                            </Grid>

                            <Grid container direction={"row"}>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        value={ServiceWorkerType.SuperServiceWorkerPlus}
                                        control={<Radio />}
                                        label={UserServiceWorker.GetLabel(ServiceWorkerType.SuperServiceWorkerPlus)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Alert severity="info">
                                        Leistungsbestätigungen, QM Management, Materialwirtschaft, Objekt-Leistungen bestätigen
                                    </Alert>
                                </Grid>
                            </Grid>

                        </RadioGroup>
                    </Grid>
                    <Grid item container xs={12} alignItems="center" spacing={3}>
                        {onInviteUser &&
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size='large'
                                    endIcon={<AddToHomeScreen />}
                                    disabled={loading}
                                    onClick={async () => {
                                        onInviteUser(currentServiceWorker);
                                    }}
                                >
                                    {getInviteButtonLabel(currentServiceWorker.type)}
                                </Button>
                            </Grid>
                        }
                        {currentServiceWorker.activationCode &&
                            <Grid item xs={4}>
                                <Alert severity="info">
                                    <AlertTitle>Login Code</AlertTitle>
                                    {currentServiceWorker.activationCode}
                                </Alert>
                            </Grid>
                        }
                    </Grid>
                    {(currentServiceWorker.type == ServiceWorkerType.ObjectLeader || currentServiceWorker.type == ServiceWorkerType.ServiceWorkerPlus || currentServiceWorker.type == ServiceWorkerType.SuperServiceWorkerPlus) &&
                        <Grid item xs={12} container spacing={3}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SupportAgent />}
                                    endIcon={<Mail />}
                                    disabled={loading}
                                    onClick={async () => {

                                        setLoading(true);
                                        let userId = getUserIdFromStorage();
                                        if (userId)
                                            await api.registerNewUser(currentServiceWorker.email, "", "Objektleiter", "Objektleiter", currentServiceWorker.name, "", UserType.Customer, userId, enqueueSnackbar);
                                        setLoading(false);
                                    }}
                                >
                                    Als Kunde Einladen
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    style={{ marginRight: 'auto' }}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SupervisedUserCircle />}
                                    endIcon={<Mail />}
                                    disabled={loading}
                                    onClick={async () => {

                                        setLoading(true);
                                        let userId = getUserIdFromStorage();
                                        if (userId)
                                            await api.registerNewUser(currentServiceWorker.email, "", "Objektleiter", "Objektleiter", currentServiceWorker.name, "", UserType.Manager, userId, enqueueSnackbar);
                                        setLoading(false);

                                    }}
                                >
                                    Als Manager Einladen
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={loading && <CircularProgress size={32} />}
                    endIcon={<Save />}
                    disabled={loading || !isValidForSave()}
                    onClick={async () => {
                        if (!isValid()) return;

                        setLoading(true);
                        await onSave(currentServiceWorker);
                        setLoading(false);

                        onClose();
                    }}
                >
                    Speichern
                </Button>
            </DialogActions>
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
