import { AppointmentModel, SchedulerDateTime } from "@devexpress/dx-react-scheduler";
import { DayOfWeek } from "./datetime/DayOfWeek";
import { SENSOR_COUNT_TRIGGER_DEFAULT, MAC_ADDRESS_DEFAULT, IS_SWITCH_ON_SENSOR_COUNT_TRIGGER_DEAULT, IS_SWITCH_ON_SENSOR_COUNT_TRIGGER_FOR_MULTI_SENSORS, DEFAULT_DAYS_OF_WEEK_ENABLED, CURRENT_DFU_TRIES_DEFAULT } from "./defaults";
import { SensorLicense } from "./GroupLicenseTable";
import { UserServiceWorker } from "./UserServiceWorker";
import { ObjectUnit } from "./ObjectUnit";

export enum GroupSettingsUpdateType {
    Standard = 0,
    Template = 1
}

export enum ToiletType {
    Neutral = 0,
    Male = 1,
    Female = 2,
    Meeting = 3,
    Custom = 100,
}



export enum ThemeCode {
    Light = 0,
    Dark = 1
}

export enum DisplayLastCleaningType {
    DateTime = 0,
    Time = 1,
}

export enum ContentCode {
    News = 0,
    Weather = 1,
    Advertisements = 2,
    WebView = 3,
    Combination = 254,
    None = 255
}

export enum ContentDisplayColorMode {
    ColorChange = 0,
    CustomColor = 1,
    ColorCombination = 2,
}

export enum ContentDisplayTextAnimationMode {
    NoAnimation = 0,
    HorizontalSpin = 1,
    Pulse = 2,
}

export enum GroupSettingsType {
    Default = 0,
    Template = 1
}

export enum GroupSettingsIntervalType {
    Interval = 0,
    Specific = 1,
    Calendar = 2
}

export enum GroupSettingsSmilesType {
    Standard = 0,
    Christmas = 1,
    Eastern = 2,
    Silvester = 3,
    Carnival = 4,
    Spring = 5,
    Summer = 6,
    Autumn = 7,
    October = 8,
    Winter = 9,
    Christmas2 = 10,
    Eastern2 = 11,
    Silvester2 = 12,
    Carnival2 = 13,
    Spring2 = 14,
    Summer2 = 15,
    Autumn2 = 16,
    October2 = 17,
    Winter2 = 18,
    Halloween2 = 19
}

export enum PublicFeedbackModeType {
    Standard = 0,
    OnlyFeedbackQuestion = 1,
    OnlyIncident = 2,
    OnlyPayPerService = 3
}

export enum IncidentModeType {
    Standard = 0,
    WithName = 1,
    WithNameAndFreeText = 2,
    WithoutNameButFreeText = 3,

}

export enum GroupSettingsServiceWorkerActionDisplayType {
    ServiceAndTimeTracking = 0,
    OnlyTimeTracking = 1,
    OnlyService = 2,
}

export class GroupSettings {
    public updateType !: GroupSettingsUpdateType;
    public id!: string | null;
    public userId !: string | null;
    public templateId!: string | null;
    public name!: string | null;
    public systemETag!: string | null;
    public systemTimestamp!: string | null;
    public locationInformation!: LocationInformation | null;
    public cleaningOperations!: CleaningOperations | null;
    public notification!: NotificationInformation | null;
    public users!: Users | null;
    public loraSensorControl!: LoraSensorControl | null;
    public sensorControl!: SensorControl | null;
    public feedbacks!: Feedback | null;
    public intervalControl!: GroupSettingsIntervalControl | null;
    public materialConsumptions!: MaterialConsumptions | null;
    public incidentReports!: IncidentReports | null;
    public payPerServiceControl!: PayPerServicesControl | null;

    public content!: Content | null;
    public sideBarImage!: GroupSettingsSideBarImage | null;
    public logo!: GroupSettingsLogo | null;
    public isDeviceRestartEnabled!: boolean;

    //Default Settings
    public constructor(id: string) {
        this.id = id;
        this.systemETag = null;
        this.systemTimestamp = null;
        this.locationInformation = new LocationInformation();
        this.cleaningOperations = new CleaningOperations();
        this.notification = new NotificationInformation();
        this.users = new Users();
        this.sensorControl = new SensorControl();
        this.loraSensorControl = new LoraSensorControl();
        this.feedbacks = new Feedback();
        this.intervalControl = new GroupSettingsIntervalControl();
        this.materialConsumptions = new MaterialConsumptions();
        this.incidentReports = new IncidentReports();
        this.content = new Content();
        this.sideBarImage = new GroupSettingsSideBarImage();
        this.logo = new GroupSettingsLogo();
        this.updateType = GroupSettingsUpdateType.Template;
        this.payPerServiceControl = new PayPerServicesControl();
    }

    static CreateDefaultSetting = (notificationName: string, objectName: string, levelName: string, customerName: string, id = "") => {
        let groupSettings = new GroupSettings(id);
        groupSettings.locationInformation = new LocationInformation();
        groupSettings.locationInformation.notificationName = notificationName;
        groupSettings.locationInformation.levelName = levelName;
        groupSettings.locationInformation.objectName = objectName;
        groupSettings.locationInformation.roomName = customerName;
        if (groupSettings.sensorControl) groupSettings.sensorControl.sensors = [];
        if (groupSettings.materialConsumptions) groupSettings.materialConsumptions.materialConsumptions = [];
        if (groupSettings.users) groupSettings.users.users = [];
        if (groupSettings.incidentReports) groupSettings.incidentReports.incidentReports = [];
        return groupSettings;

    }

    static CreateCheckpointSetting = (notificationName: string, objectName: string, levelName: string, customerName: string, id = "") => {
        let groupSettings = GroupSettings.CreateDefaultSetting(notificationName, objectName, levelName, customerName, id);
        return groupSettings;

    }

    static CreateAdvancedCheckpointSetting = (notificationName: string, objectName: string, objectUnit: ObjectUnit | undefined, levelName: string, customerName: string, id = "", toiletType: ToiletType, roomType: string, roomSize: number) => {
        let groupSettings = GroupSettings.CreateDefaultSetting(notificationName, objectName, levelName, customerName, id);
        if (groupSettings.locationInformation){
            groupSettings.locationInformation.toiletType = toiletType;
            groupSettings.locationInformation.roomType = roomType;
            groupSettings.locationInformation.roomSize = roomSize;

            groupSettings.locationInformation.objectUnitId = objectUnit?.id ?? "";
            groupSettings.locationInformation.objectName = objectName;

        }
        return groupSettings;

    }

    static MapToiletType = (toiletType: string) => {
        switch (toiletType){
            case "Nach Nutzungsartverzeichnis" :
            case "By Usage Type Directory" :
                return ToiletType.Custom;
            case "Neutral":
                return ToiletType.Neutral;
            case "Männlich":
            case "Male":
                return ToiletType.Male;
            case "Female":
            case "Weiblich":
                return ToiletType.Female;
            default:
                return ToiletType.Neutral;
        }
    }

    static CreateTaqtFeedbackSetting = (notificationName: string, objectName: string, levelName: string, customerName: string, id = "") => {
        let groupSettings = GroupSettings.CreateDefaultSetting(notificationName, objectName, levelName, customerName, id);
        return groupSettings;
    }

    static CreateTaqtTimeSetting = (notificationName: string, objectName: string, levelName: string, customerName: string, id = "") => {
        let groupSettings = GroupSettings.CreateDefaultSetting(notificationName, objectName, levelName, customerName, id);
        return groupSettings;
    }

    static CreateBssdSetting = (notificationName: string, objectName: string, levelName: string, customerName: string, id = "", deviceId: string) => {
        let groupSettings = GroupSettings.CreateDefaultSetting(notificationName, objectName, levelName, customerName, id);
        if (groupSettings.locationInformation) groupSettings.locationInformation.uniqueDeviceId = deviceId;
        return groupSettings;

    }
}

export class LocationInformation {
    public uniqueDeviceId!: string
    public objectName!: string;
    public levelName!: string;
    public roomName!: string;
    public areaSize: number = 0;
    public machineCount: number = 0;
    public machineUsagePercent: number = 0;
    public notificationName!: string;
    public toiletType!: ToiletType;
    public roomType !: string;
    public roomSize !: number;
    public password!: string;
    public isNavigationBarEnabled !: boolean;
    public deviceRestartTime !: string;
    public isDeviceRestartTimeEnabled !: boolean;
    public isExecuteManuellOfflineEnabled !: boolean;
    public isUploadOfflineDataEnabled !: boolean;
    public isExecuteOfflineDataNowEnabled !: boolean;
    public isLoginQrCodeEnabled !: boolean;
    public lastDeviceRestartTimestamp !: string;
    public objectUnitId !: string;
    public userPropertyIds !: string[];
    public localPassword!: string;
    public bssdInformation !: BssdInformation;
    public qrCodeInformation !: QrCodeInformation;
    public nativeApplicationVersion: string = "";
    public downloadedFilename: string = "";
    public buildVersion: string = "";
    public isAutoApkInstallEnabled: boolean = false;
    public constructor() {
        this.uniqueDeviceId = "";
        this.objectName = "";
        this.levelName = "";
        this.roomName = "";
        this.notificationName = "";
        this.toiletType = ToiletType.Neutral;
        this.password = "";
        this.isNavigationBarEnabled = false;
        this.objectUnitId = "";
        this.localPassword = "";
    }
}

export class QrCodeInformation {
    public backgroundColor!: string;
    public borderColor!: string;
    public printSize !: number;
    public fontSizeButton !: number;
    public fontSizeBottomRight !: number;
    public url !: string;
    public constructor (backgroundColor: string, borderColor: string, url : string){
        this.backgroundColor = backgroundColor;
        this.borderColor = borderColor;
        this.url = url

    }
}


export class BssdInformation {
    public maxReportTimeSeconds!: number;
    public timeZoneOffsetMinutes!: number;
    constructor(maxReportTimeSeconds: number, timeZoneOffsetMinutes: number) {
        this.maxReportTimeSeconds = maxReportTimeSeconds;
        this.timeZoneOffsetMinutes = timeZoneOffsetMinutes;
    }
}


export class CleaningOperation {
    public text!: string;
    public isEnabled!: boolean;
    public onlySuperServiceWorker: boolean = false;
    public standardItemId !: string;
    public intervalControlIds: string[] = [];
    public weighting !: number;

    constructor(text: string, isEnabled: boolean) {
        this.text = text;
        this.isEnabled = isEnabled;
    }
    static CreateInstance = (text: string, isEnabled: boolean, standardItemId: string) => {
        let ret = new CleaningOperation(text, isEnabled);
        ret.standardItemId = standardItemId;
        ret.intervalControlIds = [];
        return ret;
    }

    static Columns = {
        "text": "text"
    }
}

export enum ItemThingType {
    "MainUsageComponent" = 0,
    "RestInventory" = 1,
    "Walls&Covers" = 2,
    "Floor" = 3,
    "HardToSeeThings" = 4
}

export class ItemThing {
    public text!: string;
    public isEnabled!: boolean;
    public itemThingType !: ItemThingType;

    constructor(text: string, isEnabled: boolean, itemThingType: ItemThingType) {
        this.text = text;
        this.isEnabled = isEnabled;
        this.itemThingType = itemThingType;
    }

    static getItemThingTypeText = (itemThingType: ItemThingType, t: any) => {
        switch (itemThingType) {
            case ItemThingType.MainUsageComponent:
                return t("myUnitsSettings.itemHkMainUsageComponent")
            case ItemThingType.RestInventory:
                return t("myUnitsSettings.itemRiRemainingInventory")
            case ItemThingType["Walls&Covers"]:
                return t("myUnitsSettings.itemWdWallsAndCeilings")
            case ItemThingType.Floor:
                return t("myUnitsSettings.itemBFloor")
            case ItemThingType.HardToSeeThings:
                return t("myUnitsSettings.itemSebHardToViewAreas")
            default:
                return t("myUnitsSettings.unknown");
        }
    }
}
export class CleaningOperations {
    public cleaningOperations!: CleaningOperation[];
    public isPublicServiceEnabled!: boolean;
    public isPublicEasyServiceEnabled!: boolean;

    public forceScanForFinishSerivceMode: boolean = false;
    public forceScanForServiceModeStart: boolean = false;
    public serviceWorkingModeType !: ServiceWorkingModeType;

    public isQmControlEnabled !: boolean;
    public itemThings!: ItemThing[] | undefined;

    public constructor() {
        this.cleaningOperations = [new CleaningOperation("", true)];
    }
}

export class NotificationInformation {
    public assignedObjectLeadersCleaningEmail: string[] = [];
    public assignedObjectLeadersServiceDoneEmail: string[] = [];
    public onlineStatusEmail!: string;
    public isOnlineStatusEmailEnabled!: boolean;
    public isOnlineStatusWarnEmailEnabled!: boolean;
    public cleaningEmail!: string;
    public isCleaningEmailEnabled!: boolean;
    public assignedObjectLeadersIncidentReportEmail: string[] = [];
    public incidentReportEmail!: string;
    public isIncidentReportEmailEnabled!: boolean;
    public assignedObjectLeadersMissedServiceEmail: string[] = [];
    public missedServiceEmail!: string;
    public isMissedServiceEmailEnabled!: boolean;
    public freeTextFeedbackEmail!: string;
    public isFreeTextFeedbackEmailEnabled!: boolean;
    public freeTextIncidentEmail!: string;
    public isFreeTextIncidentEmailEnabled!: boolean;

    public feedbackQuestionEmail!: string;
    public isFeedbackQuestionEmailEnabled!: boolean;

    public qualityCheckAutoEmail!: string;
    public isQualityCheckAutoEmailEnabled!: boolean;
    public isQualityCheckDailyAutoEmailEnabled !: boolean;
    public isQualityCheckWeeklyAutoEmailEnabled !: boolean;
    public isQualityCheckMonthlyAutoEmailEnabled !: boolean;

    public serviceTriggerAutoEmail!: string;
    public isServiceTriggerAutoEmailEnabled!: boolean;
    public isServiceTriggerDailyAutoEmailEnabled !: boolean;
    public isServiceTriggerWeeklyAutoEmailEnabled !: boolean;
    public isServiceTriggerMonthlyAutoEmailEnabled !: boolean;

    public feebackAutoEmail!: string;
    public isFeedbackAutoEmailEnabled!: boolean;
    public isFeedbackDailyAutoEmailEnabled !: boolean;
    public isFeedbackWeeklyAutoEmailEnabled !: boolean;
    public isFeedbackMonthlyAutoEmailEnabled !: boolean;

    public onlineStatusAutoEmail!: string;
    public isOnlineStatusAutoEmailEnabled!: boolean;
    public isOnlineStatusDailyAutoEmailEnabled !: boolean;
    public isOnlineStatusWeeklyAutoEmailEnabled !: boolean;
    public isOnlineStatusMonthlyAutoEmailEnabled !: boolean;


    public cockpitAutoEmail!: string;
    public isCockpitDailyAutoEmailEnabled !: boolean;
    public isCockpitWeeklyAutoEmailEnabled !: boolean;
    public isCockpitMonthlyAutoEmailEnabled !: boolean;

    public hitlistAutoEmail!: string;
    public isHitlistDailyAutoEmailEnabled !: boolean;
    public isHitlistWeeklyAutoEmailEnabled !: boolean;
    public isHitlistMonthlyAutoEmailEnabled !: boolean;

    public frequencyAutoEmail!: string;
    public isFrequencyDailyAutoEmailEnabled !: boolean;
    public isFrequencyWeeklyAutoEmailEnabled !: boolean;
    public isFrequencyMonthlyAutoEmailEnabled !: boolean;

    public servicesAutoEmail!: string;
    public isServicesAutoEmailEnabled!: boolean;
    public isServicesDailyAutoEmailEnabled !: boolean;
    public isServicesWeeklyAutoEmailEnabled !: boolean;
    public isServicesMonthlyAutoEmailEnabled !: boolean;

    public isFeedbackNotificationEnabled!: boolean;
    public isIntervalNotificationEnabled!: boolean;
    public isMotionNotificationEnabled!: boolean;

    public isMissedServiceFeedbackNotificationEnabled!: boolean;
    public isMissedServiceIntervalNotificationEnabled!: boolean;
    public isMissedServiceMotionNotificationEnabled!: boolean;

    public isServiceNotificationEnabled!: boolean;

    public alarmEmailMaterialStock!: string;



    public notificationInformationWhatsAppGroup: NotificationInformationWhatsAppGroup;
    public notificationTelegramGroup: NotificationTelegramGroup;


    public constructor() {
        this.assignedObjectLeadersCleaningEmail = [];
        this.cleaningEmail = "";
        this.isCleaningEmailEnabled = false;
        this.assignedObjectLeadersIncidentReportEmail = [];
        this.incidentReportEmail = "";
        this.isIncidentReportEmailEnabled = false;
        this.notificationInformationWhatsAppGroup = new NotificationInformationWhatsAppGroup();
        this.notificationTelegramGroup = new NotificationTelegramGroup();
        this.assignedObjectLeadersMissedServiceEmail = [];
        this.missedServiceEmail = "";
        this.isMissedServiceEmailEnabled = false;
    }
}

export class NotificationTelegramGroup {
    public id !: number;
    public accessHash !: string;
    public title !: string;
    public date !: string;
    public isTelegramGroupEnabled !: boolean;
    public phoneNumber !: string;
    constructor() {
        this.id = 0;
        this.accessHash = "";
        this.title = "";
        this.date = "";
        this.isTelegramGroupEnabled = false;
        this.phoneNumber = "+";
    }
}

export class NotificationInformationWhatsAppGroup {
    public whatsAppGroupName !: string;
    public whatsAppGroupId !: string;
    public whatsAppGroupCosmosDbId !: string;
    public isWhatsAppGroupEnabled !: boolean;
    public constructor() {
        this.whatsAppGroupName = "";
        this.whatsAppGroupId = "";
        this.whatsAppGroupCosmosDbId = "";
        this.isWhatsAppGroupEnabled = true;
    }
}

export class User {
    public username!: string;
    public nfcCode!: string;
    public whatsAppPhoneNumber !: string;
    public isEnabled!: boolean;
    public userServiceWorkerId!: string;
    public changed: boolean = false;
    public distanceFromGroup: number = 0;

    constructor(username: string, nfcCode: string, whatsAppPhoneNumber: string, isEnabled: boolean) {
        this.username = username;
        this.nfcCode = nfcCode;
        this.whatsAppPhoneNumber = whatsAppPhoneNumber;
        this.isEnabled = isEnabled;
    }

    static CreateInstance = (username: string, nfcCode: string, whatsAppPhoneNumber: string, isEnabled: boolean, userServiceWorkerId: string) => {
        let user = new User(username, nfcCode, whatsAppPhoneNumber, isEnabled);
        user.userServiceWorkerId = userServiceWorkerId;
        return user;
    }
    static CreateInstanceFromServiceWorker = (serviceWorker: UserServiceWorker) => {
        let user = new User(serviceWorker.name, serviceWorker.nfcCode, serviceWorker.phoneNumber, serviceWorker.isEnabled);
        user.userServiceWorkerId = serviceWorker.id;
        return user;
    }
}

export class Users {
    public users!: User[];
    public isObjectSignInRfidEnabled !: boolean;
    public serviceWorkerActionDisplayType !: GroupSettingsServiceWorkerActionDisplayType;
    public constructor() {
        this.users = [new User('', '', '', true)];
    }
}

export enum ServiceWorkingModeType {
    PotentialFake = 0,
    NoFakeSecure = 1
}

export class LoraSensorControl {

    /* -> needed for old system */
    public amountTrigger!: number;
    public macAddress!: string;
    public isAmountTriggerEnabled!: boolean;
    /* needed for old system <- */

    public isSensorControlEnabled!: boolean;
    public sensors!: LoraSensor[];
    public deviationTime !: string | null;


    public constructor() {
        /* -> needed for old system */
        this.amountTrigger = parseInt(SENSOR_COUNT_TRIGGER_DEFAULT);
        this.macAddress = MAC_ADDRESS_DEFAULT;
        this.isAmountTriggerEnabled = IS_SWITCH_ON_SENSOR_COUNT_TRIGGER_DEAULT;
        /* needed for old system <- */
        this.isSensorControlEnabled = true;
        this.sensors = [new LoraSensor()];
    }
}

export class LoraSensor {
    public id!: string;
    public sensorName!: string;
    public sensorRoomName!: string;
    public amountTrigger!: number;
    public macAddress!: string;
    public isAmountTriggerEnabled!: boolean;
    public toiletType!: ToiletType;

    public sensorLicense: GroupSettingsSensorLicense;

    public constructor() {
        this.id = "";
        this.sensorName = "";
        this.sensorRoomName = "";
        this.amountTrigger = parseInt(SENSOR_COUNT_TRIGGER_DEFAULT);
        this.macAddress = MAC_ADDRESS_DEFAULT;
        this.isAmountTriggerEnabled = IS_SWITCH_ON_SENSOR_COUNT_TRIGGER_DEAULT;
        this.sensorLicense = new SensorLicense();
    }
}

export class SensorControl {

    /* -> needed for old system */
    public amountTrigger!: number;
    public macAddress!: string;
    public isAmountTriggerEnabled!: boolean;
    /* needed for old system <- */

    public isSensorControlEnabled!: boolean;
    public sensors!: Sensor[];
    public deviationTime !: string | null;


    public constructor() {
        /* -> needed for old system */
        this.amountTrigger = parseInt(SENSOR_COUNT_TRIGGER_DEFAULT);
        this.macAddress = MAC_ADDRESS_DEFAULT;
        this.isAmountTriggerEnabled = IS_SWITCH_ON_SENSOR_COUNT_TRIGGER_DEAULT;
        /* needed for old system <- */
        this.isSensorControlEnabled = true;
        this.sensors = [new Sensor()];
    }
}
export class GroupSettingsSensorLicense {
    public id !: string;
    constructor() {
        this.id = "";
    }
}
export class Sensor {
    public id!: string;
    public sensorName!: string;
    public sensorRoomName!: string;
    public amountTrigger!: number;
    public macAddress!: string;
    public isAmountTriggerEnabled!: boolean;
    public toiletType!: ToiletType;
    public currentDfuTries!: number;
    public maxDfuTries!: number;
    public lastSuccessDfuTimestamp!: number;
    public isBonded !: boolean;
    public maxReportTimeSeconds !: number;

    public sensorLicense: GroupSettingsSensorLicense;



    public loading: boolean = false;
    public lastMessage: string = "";
    public color: string = "#000000";

    public constructor() {
        this.id = "";
        this.sensorName = "";
        this.sensorRoomName = "";
        this.amountTrigger = parseInt(SENSOR_COUNT_TRIGGER_DEFAULT);
        this.macAddress = MAC_ADDRESS_DEFAULT;
        this.isAmountTriggerEnabled = IS_SWITCH_ON_SENSOR_COUNT_TRIGGER_DEAULT;
        this.currentDfuTries = CURRENT_DFU_TRIES_DEFAULT;
        this.sensorLicense = new SensorLicense();
    }
}

export class FeedbackQuestion {
    public text!: string;
    public isEnabled!: boolean;
    public standardItemId !: string;


    constructor(text: string, isEnabled: boolean) {
        this.text = text;
        this.isEnabled = isEnabled;
    }
    static CreateInstance = (text: string, isEnabled: boolean, standardItemId: string) => {
        let ret = new FeedbackQuestion(text, isEnabled);
        ret.standardItemId = standardItemId;
        return ret;
    }
}
export enum FeedbackDisplayType {
    PositiveNegativeNeutral = 0,
    AdvancedWithVeryPostiveNegative = 1
}

export class Feedback {

    public adminTriggerFakeAmountNeutral!: number;
    public adminTriggerFakeAmountNegative!: number;
    public adminTriggerFakeAmountVeryNegative!: number;

    public amountTriggerGood!: number;
    public amountTriggerVeryGood!: number;
    public amountTriggerOk!: number;
    public amountTriggerBad!: number;
    public amountTriggerVeryBad!: number;


    public amountTriggerCleanerPositive!: number;
    public amountTriggerCleanerNegative!: number;
    public amountTriggerCleanerNeutral!: number;
    public amountTriggerCleanerVeryPositive!: number;
    public amountTriggerCleanerVeryNegative!: number;


    public isSuggestionEnabled !: boolean;
    public isEnabledSuggestionVeryPositive !: boolean;
    public isEnabledSuggestionPositive !: boolean;
    public isEnabledSuggestionNeutral !: boolean;
    public isEnabledSuggestionNegative !: boolean;
    public isEnabledSuggestionVeryNegative !: boolean;

    public isMessengerFeedbackQuestionEnabled !: boolean;

    //feedbackquestion
    public feedbackQuestions!: FeedbackQuestion[];
    public feedbackQuestionsHeader!: string;
    public isEnabledFeedbackQuestionVeryPositive !: boolean;
    public isEnabledFeedbackQuestionPositive !: boolean;
    public isEnabledFeedbackQuestionNeutral !: boolean;
    public isEnabledFeedbackQuestionNegative !: boolean;
    public isEnabledFeedbackQuestionVeryNegative !: boolean;
    public feedbackQuestionsPositive!: FeedbackQuestion[];
    public feedbackQuestionsPositiveHeader!: string;
    public isEnabledPositiveFeedbackQuestionVeryPositive !: boolean;
    public isEnabledPositiveFeedbackQuestionPositive !: boolean;
    public isEnabledPositiveFeedbackQuestionNeutral !: boolean;
    public isEnabledPositiveFeedbackQuestionNegative !: boolean;
    public isEnabledPositiveFeedbackQuestionVeryNegative !: boolean;




    public isPublicFeedbackEnabled!: boolean;
    public isFeedbackQuestionEnabled!: boolean;
    public isFeedbackQuestionPositiveEnabled !: boolean;
    public isFeedbackCustomTextEnabled!: boolean;
    public isFeedbackOnlyCustomTextEnabled!: boolean;

    public isFeedbackCustomTextPositiveEnabled: boolean = false;
    public isFeedbackOnlyCustomTextPositiveEnabled: boolean = false;

    public publicFeedbackModeType !: PublicFeedbackModeType;
    public isPublicFeedbackRestartEnabled !: boolean;

    public isLightSmileNegativCountSame !: boolean;
    public isLightSmilePositiveCountSame !: boolean;
    public isSumDisplayEnabled!: boolean;
    public feedbackDisplayType!: FeedbackDisplayType;
    public isFeedbackControlEnabled!: boolean;
    public isNotificationEnabled!: boolean;

    public isCleanerFeedbackEnabled!: boolean;
    public feedbackCleanerDisplayType!: FeedbackDisplayType;
    public deviationTime !: string | null;

    public feedbackDisabledDuration: number = 30000;

    public veryPositiveFeedbackUrl: string = "";
    public positiveFeedbackUrl: string = "";
    public neutralFeedbackUrl: string = "";
    public negativeFeedbackUrl: string = "";
    public veryNegativeFeedbackUrl: string = "";

    public constructor() {
        this.amountTriggerGood = 0;
        this.amountTriggerVeryGood = 0;
        this.amountTriggerOk = 0;
        this.amountTriggerBad = 0;
        this.isSumDisplayEnabled = true;
        this.isFeedbackControlEnabled = true;
        this.isNotificationEnabled = true;
        this.isLightSmileNegativCountSame = false;
        this.isLightSmilePositiveCountSame = false;
    }
}

export class GroupSettingsIntervalControl {
    public daysOfWeekEnabled!: DayOfWeek[];
    public repeatDuration!: string | null;
    public startTime!: string | null;
    public endTime!: string | null;
    public deviationTime !: string | null;
    public minimumTime !: string | null;
    public minimumServiceWorker !: number;

    public specificTimes!: SpecificTimeDeviation[];
    public isIntervalControlEnabled!: boolean;
    public type!: GroupSettingsIntervalType;
    public calendarEntries !: AppointmentCosmosDbModel[];
    public constructor() {
        this.type = GroupSettingsIntervalType.Specific;
        this.repeatDuration = null;
        this.startTime = null;
        this.endTime = null;
        this.deviationTime = "00:30";
        this.specificTimes = [];
        this.isIntervalControlEnabled = true;
        this.daysOfWeekEnabled = DEFAULT_DAYS_OF_WEEK_ENABLED;

        if (!this.startTime) {
            this.startTime = "00:00:00"
        }
        if (!this.endTime) {
            this.endTime = "00:00:00"
        }
        if (!this.repeatDuration) {
            this.repeatDuration = "00:00:00"
        }
    }

    static AvailableTypes = [

        { value: GroupSettingsIntervalType.Interval, label: 'Intervall' },
        { value: GroupSettingsIntervalType.Specific, label: 'Spezifisch' },
        { value: GroupSettingsIntervalType.Calendar, label: 'Kalender' },
    ];

    static GetLabelByType = (type: GroupSettingsIntervalType) => GroupSettingsIntervalControl.AvailableTypes.filter(x => x.value === type).map(x => x.label)[0] || '';

    static AvailableWeekDayTypes = [

        { value: DayOfWeek.Monday, label: 'Montag' },
        { value: DayOfWeek.Tuesday, label: 'Dienstag' },
        { value: DayOfWeek.Wednesday, label: 'Mittwoch' },
        { value: DayOfWeek.Thursday, label: 'Donnerstag' },
        { value: DayOfWeek.Friday, label: 'Freitag' },
        { value: DayOfWeek.Saturday, label: 'Samstag' },
        { value: DayOfWeek.Sunday, label: 'Sonntag' },

    ];

    static GetWeekDayLabelByType = (type: DayOfWeek) => GroupSettingsIntervalControl.AvailableWeekDayTypes.filter(x => x.value === type).map(x => x.label)[0] || '';
}

export class AppointmentCosmosDbModel {
    /** The start date. */
    startDate !: SchedulerDateTime;
    /** The end date. */
    endDate !: SchedulerDateTime;
    /** The title. */
    title !: string;
    /** The all day flag. */
    allDay !: boolean;
    /** The identifier. */
    id !: number | string;
    /** Specifies the appointment recurrence rule. */
    rRule !: string | undefined;
    /** Specifies dates excluded from recurrence. */
    exDate !: string | undefined;

    public deviationTime !: string;
    public minimumTime !: string | null;
    public minimumServiceWorker !: number;

    static ConvertFromAppointmentModel = (model: AppointmentModel) => {
        let newObject = new AppointmentCosmosDbModel();
        newObject.startDate = model.startDate;
        if (model.endDate != undefined) newObject.endDate = model.endDate;
        if (model.title != undefined) newObject.title = model.title;
        //  if (model.allDay != undefined) newObject.allDay = model.allDay;
        newObject.allDay = false;
        if (model.id != undefined) newObject.id = model.id;
        if (model.rRule != undefined) newObject.rRule = model.rRule;
        if (model.exDate != undefined) newObject.exDate = model.exDate;
        if (model.minimumTime != undefined) newObject.minimumTime = model.minimumTime;
        if (model.minimumServiceWorker != undefined) newObject.minimumServiceWorker = model.minimumServiceWorker;

        return newObject;
    }
}

export class AppointmentRecurrenceCosmosDbModel {
    /** The start date. */
    startDate !: SchedulerDateTime;
    /** The end date. */
    endDate !: SchedulerDateTime;
    allDay !: boolean;
    rRul1 !: string;

    /** The title. */
    /** Any other properties. */

    static ConvertFromAppointmentModel = (model: AppointmentModel) => {
        let newObject = new AppointmentCosmosDbModel();
        newObject.startDate = model.startDate;
        if (model.endDate != undefined) newObject.endDate = model.endDate;
        if (model.title != undefined) newObject.title = model.title;
        if (model.allDay != undefined) newObject.allDay = model.allDay;
        if (model.id != undefined) newObject.id = model.id;
        if (model.rRule != undefined) newObject.rRule = model.rRule;
        if (model.exDate != undefined) newObject.exDate = model.exDate;
        return newObject;
    }
}

export class SpecificTimeDeviation {
    public specificTime !: any;
    public deviationTime !: string;
    public minimumTime !: string | null;
    public minimumServiceWorker !: number;
    public daysOfWeekEnabled!: DayOfWeek[];
    public isSpecificDaysOfWeekEnabled: boolean = false;
    public constructor(specificTime: any, deviationTime: string, minimumTime: string, minimumServiceWorker: number, daysOfWeekEnabled: DayOfWeek[], isSpecificDaysOfWeekEnabled: boolean) {
        this.deviationTime = deviationTime;
        this.specificTime = specificTime;
        this.minimumTime = minimumTime;
        this.minimumServiceWorker = minimumServiceWorker;
        this.daysOfWeekEnabled = daysOfWeekEnabled;
        this.isSpecificDaysOfWeekEnabled = isSpecificDaysOfWeekEnabled;
    }
}

export class MaterialConsumption {
    public text!: string;
    public amount: number = 0;
    public isEnabled!: boolean;
    public standardItemId !: string;
    public objectId !: string;
    public minStock !: number;
    public maxStock !: number;
    public alertEmail !: string;
    constructor(text: string, isEnabled: boolean) {
        this.text = text;
        this.isEnabled = isEnabled;
    }
    static CreateInstance = (text: string, isEnabled: boolean, standardItemId: string) => {
        let ret = new MaterialConsumption(text, isEnabled);
        ret.standardItemId = standardItemId;
        return ret;
    }

    static CreateObjectInstance = (text: string, isEnabled: boolean, standardItemId: string, objectId: string, minStock: number, maxStock: number, alertEmail: string) => {
        let ret = new MaterialConsumption(text, isEnabled);
        ret.standardItemId = standardItemId;
        ret.objectId = objectId;
        ret.minStock = minStock;
        ret.maxStock = maxStock;
        ret.alertEmail = alertEmail;
        return ret;
    }
}

export class MaterialConsumptions {
    public materialConsumptions!: MaterialConsumption[];
    public isMaterialConsumptionEnabled!: boolean;
    public isUserStockEnabled!: boolean;
    public isMaterialConsumptionInUserStockIncluded !: boolean;
    public constructor() {
        this.materialConsumptions = [new MaterialConsumption("", true)];
        this.isMaterialConsumptionEnabled = false;
    }
}

export class GroupSettingsIncidenReport {
    public text!: string;
    public isEnabled!: boolean;
    public standardItemId !: string;
    public nameExceptionList: string[] = [];
    public groupId: string = "";
    constructor(text: string, isEnabled: boolean) {
        this.text = text;
        this.isEnabled = isEnabled;
    }
    static CreateInstance = (text: string, isEnabled: boolean, standardItemId: string) => {
        let ret = new GroupSettingsIncidenReport(text, isEnabled);
        ret.standardItemId = standardItemId;
        return ret;
    }

    static Columns = {
        id: 'id',
        incident: 'incident',
        sum: 'sum'
    }
}

export class GroupSettingsIncidenName {
    public text!: string;
    public isEnabled!: boolean;
    public price !: number
    constructor(text: string, isEnabled: boolean, price: number) {
        this.text = text;
        this.isEnabled = isEnabled;
        this.price = price;
    }
    static CreateInstance = (text: string, isEnabled: boolean, price: number) => {
        let ret = new GroupSettingsIncidenName(text, isEnabled, price);
        return ret;
    }
}

export class IncidentReports {
    public incidentModeType !: IncidentModeType;
    public incidentReports!: GroupSettingsIncidenReport[];
    public incidentNames!: GroupSettingsIncidenName[];
    public isIncidentReportEnabled!: boolean;
    public isIncidentVisitorEnabled!: boolean;
    public isIncidentVisitorCustomTextEnabled!: boolean;
    public isIncidentVisitorWithNameEnabled!: boolean;
    public isIncidentVisitorWithNamePriceEnabled!: boolean;
    public constructor() {
        this.incidentReports = [new GroupSettingsIncidenReport("", true)];
        this.isIncidentReportEnabled = true;
    }
}

export class PayPerServicesControl {
    public payPerServiceModeType !: IncidentModeType;
    public payPerServiceItems!: GroupSettingsIncidenReport[];
    public payPerServiceItemNames!: GroupSettingsIncidenName[];
    public isPayPerServiceEnabled!: boolean;
    public isPayPerServiceVisitorEnabled!: boolean;

    public isPayPerServiceCustomTextEnabled!: boolean;
    public isPayPerServiceWithNameEnabled!: boolean;
    public isPayPerServiceWithNamePriceEnabled!: boolean;
    public constructor() {
        this.payPerServiceItems = [new GroupSettingsIncidenReport("", true)];
        this.isPayPerServiceEnabled = true;
    }
}

export class ContentCombinationItem {
    contentCode!: ContentCode;
    weather!: ContentWeather | null;
    news!: ContentNews | null;
    webView!: ContentWebView | null;

    public sideBarImageItemIds: string[] = [];
    public slideDuration: number = ContentCombinationItem.minSlideDuration;
    public static minSlideDuration: number = 1;
    public static maxSlideDuration: number = 300;
    public activated: boolean = true;
    public isSumTogetherSlidesDurationEnabled: boolean = true;
}

export class Content {
    public displayBackgroundColor!: string;
    contentCode!: ContentCode;
    public displayColorMode !: ContentDisplayColorMode;
    public displayTextAnimationMode1 !: ContentDisplayTextAnimationMode;
    public displayTextAnimationMode2 !: ContentDisplayTextAnimationMode;
    displayLastCleaningType!: DisplayLastCleaningType;
    themeCode!: ThemeCode;
    weather!: ContentWeather | null;
    news!: ContentNews | null;
    webView!: ContentWebView | null;

    public contentCombinationItems!: ContentCombinationItem[];
    public isContentEnabled!: boolean;

    isRfidExtraLoginEnabled!: boolean;
    isServiceListOnWaitingScreenEnabled!: boolean;
    isFeedbackQuestionListOnWaitingScreenEnabled!: boolean;
    isIncidentVisitorListOnWaitingScreenEnabled!: boolean;
    public maxWaitingScreenInMinutes: number = 1;
    public isWaitingScreenForScanAppEnabled: boolean = false;
    public isWaitingScreenServiceCancelEnabled: boolean = false;
    public isWaitingScreenCountdownDisplayEnabled: boolean = false;

    inProgressText1!: string;
    inProgressText2!: string;
    inProgressText3!: string;
    inProgressText4!: string;
    inProgressText5!: string;

    webViewUrl !: string;



    feedbackQuestionPastInHours!: number;

    isFeedbackQuestionListOnServiceModeEnabled!: boolean;
    isIncidentVisitorListOnServiceModeEnabled!: boolean;
    isObjectPresenceVisibleEnabled!: boolean;
    isLastCheckEnabled!: boolean;
    isLastCleaningEnabled!: boolean;


    public byeText1 !: string;
    public byeText1Color !: string;
    public byeText2 !: string;
    public byeText2Color !: string;
    public publicText1!: string;
    public publicText1Color !: string;

    public publicDisplayBackgroundColor!: string;
    public publicDisplayText1!: string;
    public publicDisplayText1Color !: string;
    public publicDisplayText2!: string;
    public publicDisplayText2Color !: string;

    public displayText1!: string;
    public displayText1Color !: string;
    public displayText2!: string;
    public displayText2Color !: string;
    public qrCodeUrl!: string;
    public static minWaitingScreenInMinutesValue: number = 1;
    public static maxWaitingScreenInMinutesValue: number = 300;
    public smilesType: GroupSettingsSmilesType = GroupSettingsSmilesType.Standard;

    public isEasyLoginEnabled!: boolean;
    public constructor() {
        this.themeCode = ThemeCode.Light;
        this.contentCode = ContentCode.None;
        this.weather = new ContentWeather();
        this.news = new ContentNews();
        this.contentCombinationItems = [];
        this.isContentEnabled = true;
        this.displayText1 = "";
        this.displayText2 = "";
        this.qrCodeUrl = "";
    }

    public static getContentCodeString = (contentCode: ContentCode, t : any) => {
        switch (contentCode) {
            case ContentCode.News:
                return t("myUnitsSettings.news");
            case ContentCode.Advertisements:
                return t("myUnitsSettings.advertisements");
            case ContentCode.Weather:
                return t("myUnitsSettings.weather");
            case ContentCode.WebView:
                return "IFrame";
            case ContentCode.Combination:
                return t("myUnitsSettings.combination");
            default:
                return "Nicht vorhanden"
        }
    }
}

export class ContentNews {
    public language!: string;
    public constructor() {
        this.language = "";
    }

    public static createInstance = (lang: string) => {
        let news = new ContentNews();
        news.language = lang;
        return news;
    }
}

export class ContentWebView {
    public url!: string;
    public constructor() {
        this.url = "";
    }

    public static createInstance = (url: string) => {
        let webview = new ContentWebView();
        webview.url = url;
        return webview;
    }
}

export class ContentWeather {
    public city!: string;
    public longitude !: string;
    public latitude !: string;
    public constructor() {
        this.city = "";
        this.longitude = "";
        this.latitude = "";
    }

    public static createInstance = (city: string, longitude: string, latitude: string) => {
        let weather = new ContentWeather();
        weather.city = city;
        weather.longitude = longitude;
        weather.latitude = latitude;
        return weather;
    }
}

export class GroupSettingsSideBarImage {
    public items: GroupSettingsSideBarImageItem[] = [];
}

export class GroupSettingsSideBarImageItem {
    public id: string = '';
    public activated: boolean = true;
    public slideDuration: number = GroupSettingsSideBarImageItem.minSlideDuration;
    public fileName: string = '';
    public contentType: string = '';


    public static minSlideDuration: number = 4;
    public static maxSlideDuration: number = 300;

    public static adaptSlideDurationValue = (value: number) => {

        if (isNaN(value))
            value = GroupSettingsSideBarImageItem.minSlideDuration;

        if (value < GroupSettingsSideBarImageItem.minSlideDuration)
            value = GroupSettingsSideBarImageItem.minSlideDuration;

        if (value > GroupSettingsSideBarImageItem.maxSlideDuration)
            value = GroupSettingsSideBarImageItem.maxSlideDuration;

        return value;
    }
}

export class GroupSettingsLogo {
    public fileName: string = '';
    public contentType: string = '';
    constructor() {
        this.fileName = "";
        this.contentType = "";
    }
}



