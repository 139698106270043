import React from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { IconButton, Tooltip } from '@mui/material';
import { Edit, Delete, Visibility, VisibilityOff, Done, Shop } from '@mui/icons-material';
import { CurrencyHelper } from "../../../helpers/CurrencyHelper";
import { Product } from '../../../models/Product';
import { errorColor } from '../../../styles/colors';
import { UserAccount } from '../../../models/UserAccount';
import { ShopPageAdminDialog } from '../portal/checkout/ShopPageAdminDialog';
import { ReactComponent as LicenseSvg } from '../../../assets/svgs/license_grey.svg'
import { primaryColor } from '../../../App';
import { GroupLicenseTable, UserManagementGroups } from '../../../models/GroupLicenseTable';
import { useTranslation } from 'react-i18next';

export const UserManagementPageDataTable = ({ rows, groups, onEdit, onDelete, handleSelection = undefined }
    : { rows: UserAccount[], groups: UserManagementGroups[], onEdit: (row: UserAccount) => Promise<any>, onDelete: (row: UserAccount) => Promise<any>, handleSelection?: any }) => {

    const [pageSize, setPageSize] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [isManageLicenseDialogVisible, setManageLicenseDialogVisible] = React.useState(false);
    const [currentSelectedUser, setCurrentSelectedUser] = React.useState<UserAccount>();
    const [selection, setSelection] = React.useState<(string | number)[]>([]);
    const {t} = useTranslation();

    const columns = [
        {
            name: Product.Columns.actions,
            title: " ",
            getCellValue: (row: UserAccount) => {
                return <>
                    <Tooltip title={t("myUnitsSettings.edit")}>
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                await onEdit(row);
                            }}
                            size="large">
                            <Edit />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Lizenz verwalten">
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                setManageLicenseDialogVisible(true);
                                setCurrentSelectedUser(row);
                            }}
                            size="large">
                            <LicenseSvg fill={primaryColor} />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Löschen">
                        <IconButton
                            style={{ color: errorColor }}
                            onClick={async () => {
                                await onDelete(row);
                            }}
                            size="large">
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </>;
            },
        },
        {
            name: UserAccount.Columns.IsTermsOfUseAccepted,
            title: "Nutzungsbedienung akzeptiert",
            getCellValue: (row: UserAccount) => row.isTermsOfUseAccepted ? "Ja" : "Nein",
        },
        {
            name: UserAccount.Columns.isNewsletterActive,
            title: "Newsletter Aktiv",
            getCellValue: (row: UserAccount) => row.isNewsletterActive ? "Ja" : "Nein",
        },
        {
            name: UserAccount.Columns.mail,
            title: "Email",
            getCellValue: (row: UserAccount) => row.mail,
        },
        {
            name: UserAccount.Columns.firstname,
            title: "Vorname",
            getCellValue: (row: UserAccount) => row.firstname,
        },
        {
            name: UserAccount.Columns.lastname,
            title: "Nachname",
            getCellValue: (row: UserAccount) => row.lastname,
        },
        {
            name: UserAccount.Columns.languageChoice,
            title: "Touch Display Sprache",
            getCellValue: (row: UserAccount) => row.languageChoice,
        },
        {
            name: UserAccount.Columns.userType,
            title: "Typ",
            getCellValue: (row: UserAccount) => `${row.userType} - ${UserAccount.GetLabelByType(row.userType)}`,
        },
        {
            name: UserAccount.Columns.company,
            title: "Firma",
            getCellValue: (row: UserAccount) => row.company,
        },
        {
            name: UserAccount.Columns.uid,
            title: "UID",
            getCellValue: (row: UserAccount) => row.uid,
        },
        {
            name: UserAccount.Columns.atu,
            title: "FN",
            getCellValue: (row: UserAccount) => row.atu,
        },
        {
            name: UserAccount.Columns.customerNumber,
            title: "Kundennummer",
            getCellValue: (row: UserAccount) => row.customerNumber,
        },
        {
            name: UserAccount.Columns.activated,
            title: t("myUnitsSettings.activated"),
            getCellValue: (row: UserAccount) => row.activated ? "Aktiviert" : t("myUnitsSettings.deactivated"),
        },
        {
            name: "groups",
            title: "Einheiten",
            getCellValue: (row: UserAccount) => {
                let all = groups.find(el => el.userId == row.id);
                return all?.groups.reduce((prevVal, curVal) => {
                    console.log(prevVal, curVal);
                    if (!curVal.notificationName) return prevVal
                    return curVal.notificationName + ", " + prevVal;
                }, "");;
            },
        },
        {
            name: "deviceId",
            title: "Device Ids",
            getCellValue: (row: UserAccount) => {
                let all = groups.find(el => el.userId == row.id);
                return all?.groups.reduce((prevVal, curVal) => {
                    console.log(prevVal, curVal);
                    if (!curVal.uniqueDeviceId) return prevVal
                    return curVal.uniqueDeviceId + ", " + prevVal;
                }, "");;
            },
        },
        {
            name: UserAccount.Columns.invitedFromUserId,
            title: "Ersteller (Besitzer)",
            getCellValue: (row: UserAccount) => rows.find(el => el.id === row.invitedFromUserId)?.mail || "",
        }

    ];

    const [defaultColumnWidths] = React.useState(columns.map(el => { return { columnName: el.name, width: 180 } }));
    const [defaultHiddenColumnNames] = React.useState([]);
    return (
        <div>
            <Grid
                rows={rows}
                getRowId={(row: UserAccount) => row.id}
                columns={columns}
            >
                <FilteringState
                    columnExtensions={[
                        {
                            columnName: Product.Columns.actions,
                            filteringEnabled: false,
                        }
                    ]}
                />
                <IntegratedFiltering />
                <SelectionState
                    selection={selection}
                    onSelectionChange={(x) => {
                        setSelection([...x]);
                        if (handleSelection) handleSelection(x);
                    }}
                />
                <IntegratedSelection />


                <SortingState
                    sorting={[{ columnName: Product.Columns.filter, direction: 'asc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />

                <VirtualTable
                    height={1000}
                    columnExtensions={[
                        {
                            columnName: Product.Columns.unitPrice,
                            width: 128,
                        },
                        {
                            columnName: Product.Columns.actions,
                            width: 160,
                        },
                    ]}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableSelection showSelectAll />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <TableColumnVisibility
                    hiddenColumnNames={[Product.Columns.filter]}
                />
                <Toolbar />
                <ColumnChooser />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            {currentSelectedUser &&
                <ShopPageAdminDialog open={isManageLicenseDialogVisible} setOpen={setManageLicenseDialogVisible} showall={true} selectedUserAccount={currentSelectedUser} />
            }
        </div>
    );
};
