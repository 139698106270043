import React from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSorting, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { Button, IconButton, Tooltip } from '@mui/material';
import { Edit, Delete, Visibility, VisibilityOff, Done, Shop, PhotoAlbum } from '@mui/icons-material';
import { CurrencyHelper } from "../../../helpers/CurrencyHelper";
import { Product } from '../../../models/Product';
import { errorColor } from '../../../styles/colors';
import { UserAccount } from '../../../models/UserAccount';
import { ShopPageAdminDialog } from '../portal/checkout/ShopPageAdminDialog';
import { ReactComponent as LicenseSvg } from '../../../assets/svgs/license_grey.svg'
import { primaryColor } from '../../../App';
import { GroupLicenseTable, UserManagementGroups } from '../../../models/GroupLicenseTable';
import { StandardWorkItem } from '../../../models/StandardItem';
import { formatEuropeanDateTime } from '../../../helpers/date-management';
import { DownloadFileImageDialog } from './DownloadFileImageDialog';
import { FileBlobType } from '../../../models/FileBlobType';
import { useTranslation } from 'react-i18next';

export const StandardItemDataTable = ({ rows, users, onEdit, onDelete, onShowImages }
    : { rows: StandardWorkItem[], users: UserAccount[], onEdit: (row: StandardWorkItem) => Promise<any>, onDelete: (row: StandardWorkItem) => any, onShowImages: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [isManageLicenseDialogVisible, setManageLicenseDialogVisible] = React.useState(false);
    const [currentSelectedUser, setCurrentSelectedUser] = React.useState<UserAccount>();
    const {t} = useTranslation();

    const PhotoCell = (props: any) => {
        const { style, row, ...restProps } = props;
        return (
            <Table.Cell
                {...restProps}
                style={{
                    ...style
                }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        onShowImages(row);
                    }}
                    endIcon={<PhotoAlbum></PhotoAlbum>}
                >{row.photos.length} Fotos anzeigen</Button>
            </Table.Cell>
        )
    };

    const Cell = (props: any) => {
        const { column, row } = props;
        if (column.name === StandardWorkItem.Columns.photoContent && row.photos) {
            return <PhotoCell {...props} />
        }
        return <Table.Cell {...props} />;
    };

    const columns : any[] = [
        {
            name: StandardWorkItem.Columns.actions,
            title: " ",
            getCellValue: (row: StandardWorkItem) => {
                return <>
                    <Tooltip title={t("myUnitsSettings.edit")}>
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                await onEdit(row);
                            }}
                            size="large">
                            <Edit />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Löschen">
                        <IconButton
                            style={{ color: errorColor }}
                            onClick={async () => {
                                await onDelete(row);
                            }}
                            size="large">
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </>;
            },
        },
        {
            name: StandardWorkItem.Columns.id,
            title: "Id",
            getCellValue: (row: StandardWorkItem) => row.id,
        },
        {
            name: StandardWorkItem.Columns.creatorUserId,
            title: "Ersteller",
            getCellValue: (row: StandardWorkItem) => {
                let user = users.find(x => x.id == row.creatorUserId);
                if (!user) return "User gelöscht";
                return `${user.firstname} ${user.lastname} | ${user.mail} | ${user.company}`
            }
        },
        {
            name: StandardWorkItem.Columns.text,
            title: "Text",
            getCellValue: (row: StandardWorkItem) => row.text,
        },
        {
            name: StandardWorkItem.Columns.itemType,
            title: "Typ",
            getCellValue: (row: StandardWorkItem) => `${row.itemType} - ${StandardWorkItem.GetLabelByType(row.itemType)}`,
        },
        {
            name: StandardWorkItem.Columns.createdTimestamp,
            title: "Erstellungsdatum",
            getCellValue: (row: StandardWorkItem) => formatEuropeanDateTime(row.createdTimestamp),
        },
        {
            name: StandardWorkItem.Columns.isAdminItem,
            title: "Von Admin erstellt",
            getCellValue: (row: StandardWorkItem) => row.addedFromAdmin ? "Ja" : "Nein",
        },
        {
            name: StandardWorkItem.Columns.activated,
            title: t("myUnitsSettings.activated"),
            getCellValue: (row: StandardWorkItem) => row.activated ? "Ja" : "Nein",
        },
        {
            name: StandardWorkItem.Columns.photoContent,
            title: "Bilder",
            getCellValue: (row: StandardWorkItem) => JSON.stringify(row.photos),
        }
    ];
    console.log(rows);
    const [defaultColumnWidths] = React.useState(columns.map(el => {
        if (!el) return { columnName: "sss", width: 180 };

        if (el.name == StandardWorkItem.Columns.creatorUserId) return { columnName: el.name, width: 350 }
        else if (el.name == StandardWorkItem.Columns.photoContent) return { columnName: el.name, width: 220 }
        return { columnName: el.name, width: 180 }
    }));
    const [defaultHiddenColumnNames] = React.useState([]);
    return (
        <div>
            <Grid
                rows={rows}
                getRowId={(row: UserAccount) => row.id}
                columns={columns}
            >

                <FilteringState
                    columnExtensions={[
                        {
                            columnName: Product.Columns.actions,
                            filteringEnabled: false,
                        }
                    ]}
                />
                <IntegratedFiltering />

                <SortingState
                    sorting={[{ columnName: Product.Columns.filter, direction: 'asc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />

                <Table
                    cellComponent={Cell}
                    columnExtensions={[
                        {
                            columnName: Product.Columns.unitPrice,
                            width: 128,
                        },
                        {
                            columnName: Product.Columns.actions,
                            width: 160,
                        },
                    ]}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <TableColumnVisibility
                    hiddenColumnNames={[StandardWorkItem.Columns.id, StandardWorkItem.Columns.creatorUserId]}
                />
                <Toolbar />
                <ColumnChooser />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            {currentSelectedUser &&
                <ShopPageAdminDialog open={isManageLicenseDialogVisible} setOpen={setManageLicenseDialogVisible} showall={true} selectedUserAccount={currentSelectedUser} />
            }

        </div>
    );
};
