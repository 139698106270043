import React, { useCallback, useRef } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import { Edit, Delete, PhonelinkSetup, AddToHomeScreen, BuildOutlined, Apartment, AlarmOn, Add, SwitchCamera, FactCheck, PieChart, Download, DisplaySettings } from '@mui/icons-material';
import { ReactComponent as LicenseSvg } from '../../../assets/svgs/license_grey.svg'
import { UserProperty } from '../../../../models/UserProperty';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { Product } from '../../../../models/Product';
import { errorColor } from '../../../../styles/colors';
import { ServiceWorkerType, UserServiceWorker } from '../../../../models/UserServiceWorker';
import { GroupSelectionAssignUserServiceWorkerDialog } from './GroupSelectionAssignUserServiceWorkerDialog';
import { api } from '../../../../api';
import { formatEuropeanDate, formatEuropeanDateSec, formatEuropeanDateTime } from '../../../../helpers/date-management';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { ServiceWorkerObjectSessionDialog } from '../../../dialogs/tables/ServiceWorkerObjectSessionDialog';
import { buildCellStringArray } from '../../../common/AccordionForStringArray';
import { ObjectLeaderQualityCheckDialog } from '../../../dialogs/tables/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckDialog';
import { ObjectLeaderQualityCheckChartDialog } from '../../../dialogs/charts/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckChartDialog';
import { ObjectSelectionAssignServiceWorkerToObjectDialog } from './ObjectSelectionAssignServiceWorkerToObjectDialog';
import { saveExcelFile } from '../../../../helpers/tables/common';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Grid as MuiGrid } from '@mui/material';
import { useToken } from '../../../../helpers/apiHelper';
import { ImportDataButtonCount } from '../ObjectManagementPageDataTable';
import { useSnackbar } from 'notistack';
import { ReactComponent as MicrosofExcel } from '../../../../assets/svgs/microsoft-excel.svg'
import { useTranslation } from 'react-i18next';

const schema = {
    'Name*': {
        prop: 'name',
        type: String
    },
    'NFC Code': {
        prop: 'nfcCode',
        type: String
    },
    'Telefonnummer': {
        prop: 'phoneNumber',
        type: String
    },
    'Aktiviert': {
        prop: 'isEnabled',
        type: String
    },
    'Typ': {
        prop: 'type',
        type: String
    },
    'Login Username': {
        prop: 'loginUsername',
        type: String
    },
    'Email': {
        prop: 'email',
        type: String
    },
    'Taqt NFC Code': {
        prop: 'taqtNfcCode',
        type: String
    },
}

export const buildCellObject = (object: ObjectUnit | undefined, props: any, onAssign: any = null) => {
    const { column, row, value } = props;

    const buildAssignButton = () => {
        if (!onAssign) return;
        return (
            <IconButton color="primary" component="span" onClick={async () => {
                onAssign();
            }}>
                <SwitchCamera />
            </IconButton>
        )
    }

    return (
        !object ?
            <Table.Cell {...props}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography>{value}</Typography>
                    {buildAssignButton()}
                </div>
            </Table.Cell>
            :
            <Table.Cell {...props}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'center' }}>
                    <Typography>
                        {object?.name}
                    </Typography>
                    {buildAssignButton()}
                </div>
            </Table.Cell>
    );
}

export const UserServiceWorkerManagementPageDataTable = ({ rows, groups, onEdit, onDelete, onInviteUser, objects, onRefresh, hiddenColumnNames }
    : { rows: UserServiceWorker[], groups: GroupLicenseTable[], onEdit: (row: UserServiceWorker) => Promise<any>, onDelete: (row: UserServiceWorker) => Promise<any>, onInviteUser: (row: UserServiceWorker) => Promise<any>, objects: ObjectUnit[], onRefresh: any, hiddenColumnNames: string[] }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [groupSelectionAssignObjectDialogVisible, setGroupSelectionAssignObjectDialogVisible] = React.useState(false);
    const [objectSelectionAssignObjectToServiceWorkerDialogVisible, setObjectSelectionAssignObjectToServiceWorkerDialogVisible] = React.useState(false);

    const [openServiceWorkerSessionsDialog, setOpenServiceWorkerSessionsDialog] = React.useState(false);
    const [currentSelectedServiceWorker, setCurrentSelectedServiceWorker] = React.useState<UserServiceWorker>();

    const [openObjectLeaderQualityChecksDialog, setOpenObjectLeaderQualityChecksDialog] = React.useState(false);
    const [openObjectLeaderQualityChecksChartsDialog, setOpenObjectLeaderQualityChecksChartsDialog] = React.useState(false);
    const { t } = useTranslation();




    const exporterRef: any = useRef(null);
    const token = useToken();
    const { enqueueSnackbar } = useSnackbar();

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Servicekräfte');
        });
    };
    const [selection, setSelection] = React.useState<any[]>([]);

    const Cell = (props: any) => {
        const { column, row, value } = props;
        if (column.name === UserServiceWorker.Columns.signedInObject) {
            let object = objects.find(x => x.id == row?.serviceWorkerSignedIntoObject?.signedInObjectId);
            return buildCellObject(object, props);
        }
        if (column.name === UserServiceWorker.Columns.groups) {
            let names = groups.filter(x => x.userServiceWorkerIds && x.userServiceWorkerIds.includes(row.id)).map(x => x.notificationName);
            return buildCellStringArray(props, names, `Enthält ${names.length} Einheiten`);
        }
        if (column.name === UserServiceWorker.Columns.devices) {
            let names = groups.filter(x => x.userServiceWorkerIds && x.userServiceWorkerIds.includes(row.id)).map(x => x.uniqueDeviceId);
            return buildCellStringArray(props, names, `Enthält ${names.length} DeviceIds`);
        }
        if (column.name === UserServiceWorker.Columns.actions) {
            return <Table.Cell {...props}>
                <Tooltip title={t("myUnitsSettings.edit")}>
                    <IconButton
                        color="primary"
                        onClick={async () => {
                            await onEdit(row);
                        }}
                        size="large">
                        <Edit />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Löschen">
                    <IconButton
                        style={{ color: errorColor }}
                        onClick={async () => {
                            await onDelete(row);
                        }}
                        size="large">
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Table.Cell>;
        }
        if (column.name === UserServiceWorker.Columns.actions2) {
            return <Table.Cell {...props}>

                <Tooltip title="Servicekraft zur App einladen">
                    <IconButton
                        color="primary"
                        onClick={async () => {
                            await onInviteUser(row);
                        }}
                        size="large">
                        <AddToHomeScreen />
                    </IconButton>
                </Tooltip>


                <Tooltip title="Anmeldungen in Objekten">
                    <IconButton
                        color="primary"
                        onClick={async () => {
                            setCurrentSelectedServiceWorker(row);
                            setOpenServiceWorkerSessionsDialog(true);
                        }}
                        size="large">
                        <AlarmOn />
                    </IconButton>
                </Tooltip>
                {row.type == ServiceWorkerType.ObjectLeader &&
                    <Tooltip title="Zeige Tabelle Qualitätssicherung des Objektleiter">
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                setCurrentSelectedServiceWorker(row);
                                setOpenObjectLeaderQualityChecksDialog(true);
                            }}
                            size="large">
                            <FactCheck />
                        </IconButton>
                    </Tooltip>
                }
                {row.type == ServiceWorkerType.ObjectLeader &&
                    <Tooltip title="Zeige Diagramme Qualitätssicherung des Objektleiter">
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                setCurrentSelectedServiceWorker(row);
                                setOpenObjectLeaderQualityChecksChartsDialog(true);
                            }}
                            size="large">
                            <PieChart />
                        </IconButton>
                    </Tooltip>
                }
                <Tooltip title="Objekte zuweisen">
                    <IconButton
                        color="primary"
                        onClick={async () => {
                            setCurrentSelectedServiceWorker(row);
                            setObjectSelectionAssignObjectToServiceWorkerDialogVisible(true);
                        }}
                        size="large">
                        <Apartment />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Einheiten zuweisen">
                    <IconButton
                        color="primary"
                        onClick={async () => {
                            setCurrentSelectedServiceWorker(row);
                            setGroupSelectionAssignObjectDialogVisible(true);
                        }}
                        size="large">
                        <DisplaySettings />
                    </IconButton>
                </Tooltip>

            </Table.Cell>;
        }
        return <Table.Cell {...props} />;
    };

    const extraColumns = [
        {
            name: UserServiceWorker.Columns.actions,
            title: t("myUnitsSettings.edit"),
            getCellValue: (row: UserServiceWorker) => "actions"
        },
        {
            name: UserServiceWorker.Columns.actions2,
            title: "App Funktionen",
            getCellValue: (row: UserServiceWorker) => {
                return "actions2"
            },
        }
    ]

    const columns = [
        {
            name: UserServiceWorker.Columns.type,
            title: t("myObjectSettings.serviceStaffType"),
            getCellValue: (row: UserServiceWorker) => UserServiceWorker.GetLabel(row.type),
        },
        {
            name: UserServiceWorker.Columns.name,
            title: "Name",
            getCellValue: (row: UserServiceWorker) => row.name,
        },
        {
            name: UserServiceWorker.Columns.loginUsername,
            title: t("myObjectSettings.loginUsername"),
            getCellValue: (row: UserServiceWorker) => row.loginUsername,
        },
        {
            name: UserServiceWorker.Columns.email,
            title: t("myObjectSettings.loginEmail"),
            getCellValue: (row: UserServiceWorker) => row.email,
        },
        {
            name: UserServiceWorker.Columns.phoneNumber,
            title: t("myUnitsSettings.phoneNumber"),
            getCellValue: (row: UserServiceWorker) => row.phoneNumber,
        },
        {
            name: UserServiceWorker.Columns.signedInObject,
            title: "Aktiv im Objekt",
            getCellValue: (row: UserServiceWorker) => {
                let object = objects.find(x => x.id == row?.serviceWorkerSignedIntoObject?.signedInObjectId);
                return (
                    !object ? "Nicht Aktiv" : `${object?.name} ${object?.customerName} ${object?.addressLine} ${object?.city}`
                );
            },
        },
        {
            name: UserServiceWorker.Columns.signedInObjectSince,
            title: "Aktiv im Objekt Seit",
            getCellValue: (row: UserServiceWorker) => row?.serviceWorkerSignedIntoObject?.signedInObjectId ?
                formatEuropeanDateTime(row?.serviceWorkerSignedIntoObject?.signedInObjectSinceTimestamp) :
                "Nicht Aktiv"
        },
        {
            name: UserServiceWorker.Columns.nfcCode,
            title: "RFID Chip Code",
            getCellValue: (row: UserServiceWorker) => row.nfcCode,
        },
        {
            name: UserServiceWorker.Columns.taqtNfcCode,
            title: "Taqt NFC Code",
            getCellValue: (row: UserServiceWorker) => row.taqtNfcCode,
        },
        {
            name: UserServiceWorker.Columns.isEnabled,
            title: t("myUnitsSettings.activated"),
            getCellValue: (row: UserServiceWorker) => row.isEnabled ? "Aktiviert" : t("myUnitsSettings.deactivated"),
        },
        {
            name: UserServiceWorker.Columns.autoLogoutObjectMinutes,
            title: "Max Loginzeit im Objekt",
            getCellValue: (row: UserServiceWorker) => row.autoLogoutObjectMinutes,
        },
        {
            name: UserServiceWorker.Columns.groups,
            title: t("myUnitsSettings.assignedUnits"),
            getCellValue: (row: UserServiceWorker) => {
                return groups.filter(x => x.userServiceWorkerIds && x.userServiceWorkerIds.includes(row.id)).reduce((prevVal, curVal) => {
                    if (!curVal.notificationName) return prevVal
                    return curVal.notificationName + ", " + prevVal;
                }, "");
            },
        },
        {
            name: "devices",
            title: "DeviceIds",
            getCellValue: (row: UserServiceWorker) => {
                return groups.filter(x => x.userServiceWorkerIds && x.userServiceWorkerIds.includes(row.id)).reduce((prevVal, curVal) => {
                    if (!curVal.uniqueDeviceId) return prevVal
                    return curVal.uniqueDeviceId + ", " + prevVal;
                }, "");;
            },
        },

    ];

    const [defaultColumnWidths] = React.useState(
        [
            ...extraColumns.map(el => {
                if (el.name == UserServiceWorker.Columns.actions) {
                    return { columnName: el.name, width: 150 };
                }
                if (el.name == UserServiceWorker.Columns.actions2) {
                    return { columnName: el.name, width: 440 };
                }
                return { columnName: el.name, width: 230 }
            }),
            ...columns.map(el => {
                if (el.name == UserServiceWorker.Columns.signedInObject) {
                    return { columnName: el.name, width: 400 };
                }
                return { columnName: el.name, width: 230 }
            })
        ]);
    const [defaultHiddenColumnNames] = React.useState([]);
    const [sorting, setSorting] = React.useState([{ columnName: UserServiceWorker.Columns.name, direction: 'asc' }]);
    return (
        <div>
            <MuiGrid sx={{ width: 400, textAlign: 'center' }} container direction="column" justifyContent="center">
                <MuiGrid item>
                    <Button endIcon={<Download />} href="https://restroombird.blob.core.windows.net/excel-import-vorlage-portal/Vorlage-Import-Servicekraft.xlsx">Download Servicekraft Excel-Vorlage</Button>
                </MuiGrid>
                <MuiGrid item>
                    <ImportDataButtonCount title='Importiere Servicekräfte' schema={schema} onFinish={async (row: any) => {
                        let serviceWorker = new UserServiceWorker(row.name, row.nfcCode, row.phoneNumber, row.isEnabled, row.loginUsername, ServiceWorkerType[row.type] as any, row.email, row.taqtNfcCode, false, 0);
                        await api.createUserServiceWorker(serviceWorker, token, enqueueSnackbar);
                    }} />
                </MuiGrid >
            </MuiGrid>
            <Grid
                rows={rows}
                columns={[...extraColumns, ...columns]}
            >
                <SortingState
                    sorting={sorting as any}
                    onSortingChange={setSorting as any}
                />
                <FilteringState
                    columnExtensions={[
                        {
                            columnName: Product.Columns.actions,
                            filteringEnabled: false,
                        },
                        {
                            columnName: Product.Columns.actions2,
                            filteringEnabled: false,
                        }
                    ]}
                />
                <IntegratedFiltering />

                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selection} onSelectionChange={setSelection} />

                <IntegratedPaging />
                <IntegratedSelection />

                <Table
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableFilterRow />
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                sorting={sorting as any}
                onSave={onSave}
            />
            {currentSelectedServiceWorker &&
                <GroupSelectionAssignUserServiceWorkerDialog onRefresh={onRefresh} open={groupSelectionAssignObjectDialogVisible} setOpen={setGroupSelectionAssignObjectDialogVisible} serviceWorker={currentSelectedServiceWorker} userServiceWorkers={rows} />
            }
            {currentSelectedServiceWorker &&
                <ObjectSelectionAssignServiceWorkerToObjectDialog currentServiceWorker={currentSelectedServiceWorker} onRefresh={onRefresh} open={objectSelectionAssignObjectToServiceWorkerDialogVisible} setOpen={setObjectSelectionAssignObjectToServiceWorkerDialogVisible} groups={groups} />
            }
            {currentSelectedServiceWorker &&
                <ObjectLeaderQualityCheckDialog open={openObjectLeaderQualityChecksDialog} setOpen={setOpenObjectLeaderQualityChecksDialog} userServiceWorker={currentSelectedServiceWorker} />
            }
            {currentSelectedServiceWorker &&
                <ObjectLeaderQualityCheckChartDialog open={openObjectLeaderQualityChecksChartsDialog} setOpen={setOpenObjectLeaderQualityChecksChartsDialog} userServiceWorker={currentSelectedServiceWorker} />
            }
            {currentSelectedServiceWorker &&
                <ServiceWorkerObjectSessionDialog groups={groups} open={openServiceWorkerSessionsDialog} setOpen={setOpenServiceWorkerSessionsDialog} selectedUserServiceWorkerId={currentSelectedServiceWorker.id} objects={objects} userServiceWorker={currentSelectedServiceWorker} />
            }
        </div>
    );
};
