import React, { useCallback, useContext, useRef, useState } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { Button, CircularProgress, IconButton, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { Edit, Delete, PhonelinkSetup, AlarmOn, QrCode2, ManageAccounts, Folder, Share, Publish, MoreTime, LocalShipping, DisplaySettings } from '@mui/icons-material';
import { Product } from '../../../models/Product';
import { errorColor } from '../../../styles/colors';
import { GroupLicenseTable, ObjectManagementGroups } from '../../../models/GroupLicenseTable';
import { MaterialObjectUnit, ObjectUnit } from '../../../models/ObjectUnit';
import { GroupSelectionAssignToObjectDialog } from './GroupSelectionAssignToObjectDialog';
import { ObjectSessionByObjectIdDialog } from '../../dialogs/tables/ObjectSessionByObjectIdDialog';
import { ServiceWorkerType, UserServiceWorker } from '../../../models/UserServiceWorker';
import { buildCellStringArray } from '../../common/AccordionForStringArray';
import { useQuery } from '../portal/checkout/ShopPage';
import { useHistory } from "react-router-dom";
import { UserServiceWorkerSelectionForObjectsDialog } from './UserServiceWorkerSelectionForObjectsDialog';
import { formatEuropeanDateTime } from '../../../helpers/date-management';
import { LicenseBlack, LicenseIconGreen, LicenseIconRed, LicenseWhite } from '../../../assets';
import { AssignLicenseChoosingTableDialog, ShowCountType } from '../../dialogs/AssignLicenseChoosingTableDialog';
import { getApiToken, setObjectStorageForDrawer } from '../../../helpers/storage-management';
import { api } from '../../../api';
import { LicenseType } from '../../../models/LicenseType';
import { GroupLicense } from '../../../models/GroupLicense';
import { useSnackbar } from 'notistack';
import { ObjectFolderDialog } from './objectUnit/ObjectFolderDialog';
import { GlobalContext } from '../PortalPage';
import readXlsxFile from 'read-excel-file';
import { Grid as MuiGrid } from '@mui/material';
import { UserServiceWorkerImportForObjectsDialog } from './objectUnit/UserServiceWorkerImportForObjectsDialog';
import { ReactComponent as MicrosofExcel } from '../../../assets/svgs/microsoft-excel.svg'
import { GroupSettings, LocationInformation } from '../../../models/GroupSettings';
import { UserGroupsImportForObjectsDialog } from './objectUnit/UserGroupsImportForObjectsDialog';
import { UserServiceWorkerObjectSession } from '../../../models/UserServiceWorkerObjectSession';
import { parse } from 'date-fns';
import { StandardWorkItem } from '../../../models/StandardItem';
import { UserProperty } from '../../../models/UserProperty';
import { useTranslation } from 'react-i18next';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { ExcelHelper } from '../../../helpers/excel-helper';


export const ObjectManagementPageDataTable = ({ rows, groups, onEdit, onDelete, userServiceWorkers, onShowQrCode, onRefresh, setSelectedObjects, onAddServiceWorkerObjectSession, freeLicenses, userProperties }
    : { rows: ObjectUnit[], groups: GroupLicenseTable[], onEdit: (row: ObjectUnit) => Promise<any>, onDelete: (row: ObjectUnit) => Promise<any>, userServiceWorkers: UserServiceWorker[], onShowQrCode: any, onRefresh: any, setSelectedObjects: any, onAddServiceWorkerObjectSession: any, freeLicenses: GroupLicense[], userProperties: UserProperty[] }) => {

    const [assignStandaloneObjectLicenseDialogVisible, setAssignStandaloneObjectLicenseDialogVisible] = React.useState(false);
    const token = useRef("");
    const { enqueueSnackbar } = useSnackbar();

    const { setSelectedObjectId, setSelectedObjectName }: any = useContext(GlobalContext);

    React.useEffect(() => {
        if (!assignStandaloneObjectLicenseDialogVisible) return;
        (async () => {
            const tempToken = getApiToken(history);
            token.current = tempToken;

        })();

    }, [assignStandaloneObjectLicenseDialogVisible])
    const [selection, setSelection] = useState<any[]>([]);
    const [pageSize, setPageSize] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [groupSelectionAssignObjectDialogVisible, setGroupSelectionAssignObjectDialogVisible] = React.useState(false);
    const [groupSelectionAssignServiceWorkerDialogVisible, setGroupSelectionAssignServiceWorkerDialogVisible] = React.useState(false);
    const [currentObject, setCurrentObject] = React.useState<ObjectUnit>();
    const [openObjectSessionsDialog, setOpenObjectSessionsDialog] = React.useState(false);
    const history = useHistory();
    const chosenObject = useQuery(history).get("chosenObject");
    const [openedFromPath, setOpenedFromPath] = React.useState(false);

    const [openObjectFolderDialog, setOpenObjectFolderDialog] = React.useState(false);

    const [dataForImportServiceWorkerToObject, setDataForImportServiceWorkerToObject] = React.useState<{ object: ObjectUnit, userServiceWorker: UserServiceWorker }[]>([]);
    const [dataForImportGroupToObject, setDataForImportGroupsToObject] = React.useState<{ object: ObjectUnit, groupSettings: GroupSettings }[]>([]);

    const [isUserServiceWorkerImportForObjectsDialogVisible, setIsUserServiceWorkerImportForObjectsDialogVisible] = React.useState(false);


    const [isUserGroupsImportForObjectsDialogVisible, setIsUserGroupsImportForObjectsDialogVisible] = React.useState(false);
    const exporterRef: any = useRef(null);
    const [filters, setFilters] = React.useState([]);

    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    const [sorting, setSorting] = useState([{ columnName: ObjectUnit.Columns.name, direction: 'asc' }]);
    const { t } = useTranslation();



    const Cell = (props: any) => {
        const { column, row, value } = props;
        if (column.name === ObjectUnit.Columns.groups) {
            let names = groups.filter(x => x.objectUnitId == row.id).map(x => x.notificationName);
            return buildCellStringArray(props, names, `Enthält ${names.length} Einheiten`);
        }
        if (column.name === ObjectUnit.Columns.userProperties) {
            let names = userProperties.filter(x => row.userPropertyIds.includes(x.id)).map(x => x.name);
            return buildCellStringArray(props, names, `Enthält ${names.length} Merkmale`);
        }
        if (column.name === ObjectUnit.Columns.devices) {
            let names = groups.filter(x => x.objectUnitId == row.id).map(x => x.uniqueDeviceId);
            return buildCellStringArray(props, names, `Enthält ${names.length} DeviceIds`);
        }
        return <Table.Cell {...props} />;
    };

    if (chosenObject && !openedFromPath) {
        let currentVal = rows.find(x => x.id == chosenObject);
        if (currentVal) {
            onEdit(currentVal);
            setOpenedFromPath(true);
        }
    }

    const columns = [
        {
            name: ObjectUnit.Columns.actions,
            title: t("myUnitsSettings.edit"),
            getCellValue: (row: ObjectUnit) => {
                return <>

                    <Tooltip title={t("myUnitsSettings.edit")}>
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                await onEdit(row);
                            }}
                            size="large">
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t("myObjectSettings.registerPresence")}>
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                await onAddServiceWorkerObjectSession(row);
                            }}
                            size="large">
                            <MoreTime />
                        </IconButton>
                    </Tooltip>


                    <Tooltip title={t("myUnitsSettings.assignLicense")}>
                        <IconButton
                            color={ObjectUnit.IsStandaloneLicenseValid(row) ? "success" : "error"}
                            onClick={async () => {
                                setCurrentObject(row);
                                setAssignStandaloneObjectLicenseDialogVisible(true);
                            }}
                            size="large">
                            {ObjectUnit.IsStandaloneLicenseValid(row) ? <LicenseIconGreen /> : <LicenseIconRed />}
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={t("myUnitsSettings.delete")}>
                        <IconButton
                            style={{ color: errorColor }}
                            onClick={async () => {
                                await onDelete(row);
                            }}
                            size="large">
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </>;
            },
        },
        {
            name: ObjectUnit.Columns.folder,
            title: t("myObjectSettings.folder"),
            getCellValue: (row: ObjectUnit) => {
                return <>

                    <Tooltip title={t("myObjectSettings.manageObjectFolder")}>
                        <IconButton
                            color={ObjectUnit.IsStandaloneLicenseValid(row) ? "success" : "error"}
                            onClick={async () => {
                                //    setCurrentObject(row);
                                setSelectedObjectId(row.id);
                                setSelectedObjectName(row.name);
                                setObjectStorageForDrawer(row);
                                history.push(`/portal/object/${row.id}/shared-folders`);

                                //     setOpenObjectFolderDialog(true);
                            }}
                            size="large">
                            {ObjectUnit.IsStandaloneLicenseValid(row) ? <Folder color='secondary' /> : <Folder color='error' />}
                        </IconButton>
                    </Tooltip>
                </>;
            },
        },
        {
            name: ObjectUnit.Columns.actions2,
            title: t("myObjectSettings.appFunctions"),
            getCellValue: (row: ObjectUnit) => {
                return <>
                    {ObjectUnit.IsStandaloneLicenseValid(row) ?
                        <Tooltip title={t("object-sessions.objectRegistrations")}>
                            <IconButton
                                color="primary"
                                onClick={async () => {
                                    setOpenObjectSessionsDialog(true);
                                    setCurrentObject(row);
                                }}
                                size="large">
                                <AlarmOn />
                            </IconButton>
                        </Tooltip> :
                        <Tooltip title="Anmeldungen im Objekt (Lizenz muss gekauft werden)">
                            <IconButton
                                color="error"
                                onClick={async () => {
                                    alert("Bitte Standalone Objekt Lizenz erwerben!");
                                }}
                                size="large">
                                <AlarmOn />
                            </IconButton>
                        </Tooltip>

                    }

                    <Tooltip title={t("myObjectSettings.qrCodeDisplay")}>
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                await onShowQrCode(row);
                            }}
                            size="large">
                            <QrCode2 />
                        </IconButton>
                    </Tooltip>


                    <Tooltip title={t("myObjectSettings.assignServiceStaffToObject")}>
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                setGroupSelectionAssignServiceWorkerDialogVisible(true);
                                setCurrentObject(row);
                            }}
                            size="large">
                            <ManageAccounts />
                        </IconButton>
                    </Tooltip>


                    <Tooltip title={t("myObjectSettings.assignToUnits")}>

                        <IconButton onClick={async () => {
                            setGroupSelectionAssignObjectDialogVisible(true);
                            setCurrentObject(row);
                        }}
                            color="primary">
                            <DisplaySettings />
                        </IconButton>
                    </Tooltip>

                </>;
            },
        },
        {
            name: ObjectUnit.Columns.name,
            title: "Name",
            getCellValue: (row: ObjectUnit) => row.name,
        },
        {
            name: ObjectUnit.Columns.costLocation,
            title: t("shared-folders-objects-all.costCenter"),
            getCellValue: (row: ObjectUnit) => row.costLocation,
        },
        {
            name: ObjectUnit.Columns.objectNumber,
            title: t("shared-folders-objects-all.objectNumber"),
            getCellValue: (row: ObjectUnit) => row.objectNumber,
        },
        {
            name: ObjectUnit.Columns.description,
            title: t("shared-folders-objects-all.description"),
            getCellValue: (row: ObjectUnit) => row.description,
        },
        {
            name: ObjectUnit.Columns.customerName,
            title: t("myUnitsSettings.customerName"),
            getCellValue: (row: ObjectUnit) => row.customerName,
        },
        {
            name: ObjectUnit.Columns.userProperties,
            title: t("myObjectSettings.assignedAttributes"),
            getCellValue: (row: ObjectUnit) => {
                return userProperties.filter(x => row.userPropertyIds.includes(x.id)).reduce((prevVal, curVal) => {
                    if (!curVal.name) return prevVal
                    return curVal.name + ", " + prevVal;
                }, "");
            },
        },
        {
            name: ObjectUnit.Columns.groups,
            title: t("myUnitsSettings.assignedUnits"),
            getCellValue: (row: ObjectUnit) => {
                return groups.filter(x => x.objectUnitId == row.id).reduce((prevVal, curVal) => {
                    if (!curVal.notificationName) return prevVal
                    return curVal.notificationName + ", " + prevVal;
                }, "");;
            },
        },
        {
            name: ObjectUnit.Columns.devices,
            title: "DeviceIds",
            getCellValue: (row: ObjectUnit) => {
                return groups.filter(x => x.objectUnitId == row.id).reduce((prevVal, curVal) => {
                    if (!curVal.uniqueDeviceId) return prevVal
                    return curVal.uniqueDeviceId + ", " + prevVal;
                }, "");;
            },
        },
        {
            name: ObjectUnit.Columns.addressLine,
            title: t("myUnitsSettings.address"),
            getCellValue: (row: ObjectUnit) => row.addressLine,
        },
        {
            name: ObjectUnit.Columns.city,
            title: t("myUnitsSettings.city"),
            getCellValue: (row: ObjectUnit) => row.city,
        },
        {
            name: ObjectUnit.Columns.state,
            title: t("myUnitsSettings.state"),
            getCellValue: (row: ObjectUnit) => row.state,
        },
        {
            name: ObjectUnit.Columns.country,
            title: t("myUnitsSettings.country"),
            getCellValue: (row: ObjectUnit) => row.country,
        },
        {
            name: ObjectUnit.Columns.zipCode,
            title: t("myUnitsSettings.postalCode"),
            getCellValue: (row: ObjectUnit) => row.zipCode,
        },
        {
            name: ObjectUnit.Columns.isActivatedEnabled,
            title: t("myUnitsSettings.activated"),
            getCellValue: (row: ObjectUnit) => row.isActivatedEnabled ? t("myUnitsSettings.activated") : t("myUnitsSettings.deactivated"),
        },
        {
            name: ObjectUnit.Columns.standaloneLicenseValidUntilTimestamp,
            title: t("myObjectSettings.standaloneEnabledUntil"),
            getCellValue: (row: ObjectUnit) => ObjectUnit.IsStandaloneLicenseValid(row) ? formatEuropeanDateTime(row.standaloneLicenseValidUntilTimestamp) : t("myUnitsSettings.expired"),
        }

    ];

    const [defaultColumnWidths] = React.useState(columns.map(el => {
        if (el.name == UserServiceWorker.Columns.groups) {
            return { columnName: el.name, width: 300 };
        }
        if (el.name == UserServiceWorker.Columns.devices) {
            return { columnName: el.name, width: 300 };
        }
        if (el.name == UserServiceWorker.Columns.actions2) {
            return { columnName: el.name, width: 350 };
        }
        if (el.name == UserServiceWorker.Columns.actions) {
            return { columnName: el.name, width: 250 };
        }
        if (el.name == ObjectUnit.Columns.folder) {
            return { columnName: el.name, width: 80 };
        }
        return {
            columnName: el.name, width: 180
        }
    }));
    const [hiddenColumnNames, setHiddenColumnNames] = useState<any>([]);

    const schemaExcelObjectSession = {
        'Objektkostenstelle': {
            prop: 'Objektkostenstelle',
            type: String
        },
        'Objektname': {
            prop: 'Objektname',
            type: String
        },
        'Servicekraftname': {
            prop: 'Servicekraftname',
            type: String
        },
        'Startdatum': {
            prop: 'Startdatum',
        },
        'Endedatum': {
            prop: 'Endedatum',
        },
    }

    const schema = {
        'Nr.': {
            prop: 'Nr.',
            type: String
        },
        'Objekt Kostenstelle': {
            prop: 'Objekt Kostenstelle',
            type: String
        },
        'Einheit Name': {
            prop: 'Einheit Name',
            type: String
        },
        'Ebene': {
            prop: 'Ebene',
            type: String
        },
        'Objektleiter': {
            prop: 'Objektleiter',
            type: String
        },
        'Beschreibung': {
            prop: 'Beschreibung',
            type: String
        },
        'Objekt': {
            prop: 'Objekt',
            type: String
        },
        'Lief. an Name': {
            prop: 'Lief. an Name',
            type: String
        },
        'Lief. an Name 2': {
            prop: 'Lief. an Name 2',
            type: String
        },
        'Lief. an Adresse': {
            prop: 'Lief. an Adresse',
            type: String
        },
        'Lief. an PLZ-Code': {
            prop: 'Lief. an PLZ-Code',
            type: String
        },
        'Lief. an Ort': {
            prop: 'Lief. an Ort',
            type: String
        }
    }

    return (
        <div>
            <MuiGrid container direction={"row"} alignItems="center" spacing={1}>
                <MuiGrid xs={3} item container direction="column" alignItems={"center"} justifyContent="center">
                    <MuiGrid item>
                        <Button href="https://restroombird.blob.core.windows.net/excel-import-vorlage-portal/Vorlage-Import-Objekt.xlsx">{t("myObjectSettings.templateImportObjectDownl")}</Button>
                    </MuiGrid>
                    <MuiGrid item>
                        <ImportDataButtonCount title={t("myObjectSettings.importObjects")} schema={schema} onFinish={async (row: any) => {
                            const tempToken = getApiToken(history);
                            let objectUnit = new ObjectUnit();
                            objectUnit.name = row['Lief. an Name'];
                            objectUnit.objectNumber = row["Objekt"];
                            objectUnit.customerName = row['Lief. an Name 2'];
                            objectUnit.addressLine = row['Lief. an Adresse'];
                            objectUnit.city = row['Lief. an Ort'];
                            objectUnit.state = "";
                            objectUnit.zipCode = row['Lief. an PLZ-Code'];
                            objectUnit.country = 'Österreich'
                            objectUnit.description = row["Beschreibung"];
                            objectUnit.costLocation = row['Nr.'];
                            const added = await api.createUserObjectUnit(objectUnit, tempToken, enqueueSnackbar);
                        }} />
                    </MuiGrid >
                </MuiGrid>
                <MuiGrid xs={3} item container direction="column" alignItems={"center"} justifyContent="center">
                    <MuiGrid item>
                        <Button href="https://restroombird.blob.core.windows.net/excel-import-vorlage-portal/Vorlage-Import-Objekt(Kostenstelle)-Anwesenheit.xlsx">{t("myObjectSettings.templateObjectCostCenterAttendanceDownl")}</Button>
                    </MuiGrid>
                    <MuiGrid item>
                        <Button href="https://restroombird.blob.core.windows.net/excel-import-vorlage-portal/Vorlage-Import-Objekt(Name)-Anwesenheit.xlsx">{t("myObjectSettings.templateObjectNameAttendanceDownl")}</Button>
                    </MuiGrid>
                    <MuiGrid item>
                        <ImportDataButtonCount title={t("myObjectSettings.importObjectAttendances")} schema={schemaExcelObjectSession} onFinish={async (row: any) => {

                            const tempToken = getApiToken(history);

                            let curObject = rows.find(x => x.costLocation == row["Objektkostenstelle"] || x.name == row["Objektname"]);
                            let curServiceWorker = userServiceWorkers.find(x => x.name == row["Servicekraftname"]);
                            if (curObject && curServiceWorker) {
                                let newEntry = UserServiceWorkerObjectSession.CreateFullUserServiceWorkerObjectSession(curObject?.id, curObject?.userId, curServiceWorker?.id, parse(row["Startdatum"], "dd.MM.yyyy HH:mm", new Date()), parse(row["Endedatum"], "dd.MM.yyyy HH:mm", new Date()))
                                console.log(newEntry, "startDatum");
                                await api.createServiceWorkerObjectSession(newEntry, tempToken, enqueueSnackbar);
                            }


                        }} />
                    </MuiGrid>
                </MuiGrid>
                <MuiGrid xs={3} item container direction="column" alignItems={"center"} justifyContent="center">
                    <MuiGrid item>
                        <Button href="https://restroombird.blob.core.windows.net/excel-import-vorlage-portal/Vorlage-Import-Objektleiter-auf-Objekte.xlsx">{t("myObjectSettings.templateObjectManagerAssignmentToObjectsDownl")}</Button>
                    </MuiGrid>
                    <MuiGrid>
                        <ImportDataButton title={t("myObjectSettings.importObjectManagers")} schema={schema} onFinish={async (rows: any[]) => {

                            const tempToken = getApiToken(history);

                            let newObjects = await api.getObjectUnitsByCostLocation(tempToken, rows.map(x => x["Nr."]));


                            let serviceWorkers = await api.getServiceWorkersByName(tempToken, rows.map(x => x["Objektleiter"]));



                            let result: { object: ObjectUnit, userServiceWorker: UserServiceWorker }[] = [];

                            rows.forEach((row) => {
                                let serviceWorker = serviceWorkers.find(x => row["Objektleiter"] == x.name);
                                let objectUnit = newObjects.find(x => x.costLocation == row["Nr."]);
                                if (serviceWorker && objectUnit) {
                                    result.push({ object: objectUnit, userServiceWorker: serviceWorker });
                                }
                            })

                            setDataForImportServiceWorkerToObject(result);
                            setIsUserServiceWorkerImportForObjectsDialogVisible(true);


                        }} />
                    </MuiGrid>
                </MuiGrid>
                <MuiGrid xs={3} item container direction="column" alignItems={"center"} justifyContent="center">
                    <MuiGrid item>
                        <Button href="https://restroombird.blob.core.windows.net/excel-import-vorlage-portal/Vorlage-Import-Einheiten-auf-Objekte.xlsx">{t("myObjectSettings.templateUnitsAssignmentToObjectsDownl")}</Button>
                    </MuiGrid>
                    <MuiGrid>
                        <ImportDataButton title={t("myObjectSettings.importUnits")} schema={schema} onFinish={async (rows: any[]) => {


                            const tempToken = getApiToken(history);

                            let newObjects = await api.getObjectUnitsByCostLocation(tempToken, rows.map(x => x["Objekt Kostenstelle"]));



                            let importGroups = rows.map(x => {
                                let ret = new GroupSettings("");
                                ret.locationInformation = new LocationInformation();
                                ret.locationInformation.notificationName = x["Einheit Name"];
                                ret.locationInformation.levelName = x["Ebene"];
                                return ret;

                            });

                            let groupsToImport = await api.getGroupSettingsByLocationName(importGroups, tempToken, enqueueSnackbar);



                            let result: { object: ObjectUnit, groupSettings: GroupSettings }[] = [];

                            rows.forEach((row) => {
                                let groupToImport = groupsToImport.find(x => row["Einheit Name"] == x.locationInformation?.notificationName && row["Ebene"] == x.locationInformation?.levelName);
                                let objectUnit = newObjects.find(x => x.costLocation == row["Objekt Kostenstelle"]);
                                if (groupToImport && objectUnit) {
                                    result.push({ object: objectUnit, groupSettings: groupToImport });
                                }
                            })

                            setDataForImportGroupsToObject(result);
                            setIsUserGroupsImportForObjectsDialogVisible(true);





                        }} />
                    </MuiGrid>
                </MuiGrid>
            </MuiGrid>


            <Grid
                rows={rows}
                getRowId={(row: ObjectUnit) => row.id}
                columns={columns}
            >
                <FilteringState
                    filters={filters}
                    onFiltersChange={setFilters as any}
                />
                <IntegratedFiltering />

                <SortingState
                    sorting={sorting as any}
                    onSortingChange={setSorting as any}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selection} onSelectionChange={(ids) => {
                    setSelection(ids);
                    setSelectedObjects(rows.filter(x => ids.includes(x.id)));
                }} />
                <IntegratedSelection />
                <IntegratedPaging />

                <VirtualTable
                    height={1000}
                    cellComponent={Cell}
                    columnExtensions={[
                        {
                            columnName: Product.Columns.unitPrice,
                            width: 128,
                        },
                        {
                            columnName: Product.Columns.actions,
                            width: 160,
                        },
                    ]}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                    onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                <TableFilterRow />
                <TableColumnVisibility
                    hiddenColumnNames={[Product.Columns.filter]}
                />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <GridExporter
                hiddenColumnNames={hiddenColumnNames}
                ref={exporterRef}
                rows={rows}
                columns={columns.filter(x=>!(x.name == ObjectUnit.Columns.actions || x.name == ObjectUnit.Columns.actions2 || x.name == ObjectUnit.Columns.folder))}
                filters={filters}
                onSave={(workbook) => ExcelHelper.onSave(workbook, t("homeDrawerFilter.objects"))}
            />
            {currentObject &&
                <GroupSelectionAssignToObjectDialog onRefresh={onRefresh} open={groupSelectionAssignObjectDialogVisible} setOpen={setGroupSelectionAssignObjectDialogVisible} objectUnit={currentObject} objects={rows} />
            }
            {currentObject &&
                <UserServiceWorkerSelectionForObjectsDialog onRefresh={onRefresh} open={groupSelectionAssignServiceWorkerDialogVisible} setOpen={setGroupSelectionAssignServiceWorkerDialogVisible} currentObject={currentObject} objects={rows} />
            }


            <AssignLicenseChoosingTableDialog
                showCountType={ShowCountType.ObjectStandalone}
                visible={assignStandaloneObjectLicenseDialogVisible}
                licenses={freeLicenses}
                onClose={() => {
                    setAssignStandaloneObjectLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    if (!currentObject) return;
                    await api.assignLicenseObjectStandaloneToObject(currentObject.id, selectedLicense.id, token.current, enqueueSnackbar);
                    setAssignStandaloneObjectLicenseDialogVisible(false);
                    onRefresh();

                }}
            />
            <UserServiceWorkerImportForObjectsDialog open={isUserServiceWorkerImportForObjectsDialogVisible} setOpen={setIsUserServiceWorkerImportForObjectsDialogVisible} data={dataForImportServiceWorkerToObject} onRefresh={undefined} />
            <UserGroupsImportForObjectsDialog open={isUserGroupsImportForObjectsDialogVisible} setOpen={setIsUserGroupsImportForObjectsDialogVisible} data={dataForImportGroupToObject} onRefresh={undefined} />
            <ObjectSessionByObjectIdDialog groups={groups} open={openObjectSessionsDialog} setOpen={setOpenObjectSessionsDialog} selectedObjectUnit={currentObject} userServiceWorkers={userServiceWorkers} />
            {currentObject &&
                <ObjectFolderDialog open={openObjectFolderDialog} setOpen={setOpenObjectFolderDialog} currentObject={currentObject} objects={[]} onRefresh={undefined} />
            }
        </div>
    );
};

export const ImportDataCount = ({ schema, onFinish, title = "Import von Excel-Spreadsheet" }: { schema: any, onFinish: any, title?: string }) => {

    const [loading, setLoading] = React.useState(false);
    const [count, setCount] = React.useState(0);

    return (
        <ListItemButton component="label"        >
            <ListItemIcon>
                <MicrosofExcel height={30} />
            </ListItemIcon>
            <ListItemText primary={loading ? `Importiere ${count} Dateneinheiten` : title} />
            <input
                type="file"
                accept=".xlsx, .xls"
                name="uploadObjects"
                onChange={async (event) => {
                    if (!event.target.files) return;
                    readXlsxFile(event.target.files[0], { schema }).then(async ({ rows, errors }: any) => {
                        setLoading(true);
                        setCount(rows.length);
                        for (let row of rows) {

                            await onFinish(row);
                            setCount((prev) => prev - 1)
                        }
                        setLoading(false);

                    });
                }
                }
                hidden
            />
        </ListItemButton>
    )
}


export const ImportDataButtonCount = ({ schema, onFinish, title = "Import von Excel-Spreadsheet" }: { schema: any, onFinish: any, title?: string }) => {

    const [loading, setLoading] = React.useState(false);
    const [count, setCount] = React.useState(0);

    return (
        <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            startIcon={loading ? <CircularProgress size={32} /> : <MicrosofExcel width={30} height={30} fill="white" />}
            component="label"
            endIcon={<Publish />}
        >
            {loading ? `Importiere ${count} Dateneinheiten` : title}
            <input
                type="file"
                accept=".xlsx, .xls"
                name="uploadObjects"
                onChange={async (event) => {
                    if (!event.target.files) return;
                    readXlsxFile(event.target.files[0], { schema }).then(async ({ rows, errors }: any) => {
                        setLoading(true);
                        setCount(rows.length);
                        for (let row of rows) {
                            await onFinish(row);
                            setCount((prev) => prev - 1)
                        }
                        setLoading(false);

                    });
                }
                }
                hidden
            />
        </Button>
    )
}

export const ImportDataButton = ({ schema, onFinish, title = "Import von Excel-Spreadsheet" }: { schema: any, onFinish: any, title?: string }) => {

    const [loading, setLoading] = React.useState(false);
    const [count, setCount] = React.useState(0);

    return (
        <Button
            variant="contained"
            color="primary"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={32} /> : <MicrosofExcel width={30} height={30} fill="white" />}
            component="label"
            endIcon={<Publish />}
        >
            {loading ? `Lade ${count} Dateneinheiten` : title}
            <input
                type="file"
                accept=".xlsx, .xls"
                name="uploadObjects"
                onChange={async (event) => {
                    if (!event.target.files) return;
                    readXlsxFile(event.target.files[0], { schema }).then(async ({ rows, errors }: any) => {
                        setLoading(true);
                        setCount(rows.length);
                        await onFinish(rows);
                        setLoading(false);

                    });
                }
                }
                hidden
            />
        </Button>
    )
}